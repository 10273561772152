import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  singleInteractionQuery,
  singleNewInteractionQuery,
  updateInteraction,
  updateInteractionForAnswer,
} from './queries';
import { base64ToModel, parseAttr, pkToBase64 } from '../data-model';
import * as MInteraction from './interaction';
import { BInteraction } from './interaction';

import { apiUrl } from 'app/common/url-resolver.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { createGuid } from 'app/common/uuid-generator';
import { PeriodicalExecutor } from 'app/common/common/periodical-service/periodical-service.service';
import { environment } from 'app/../environments/environment';
import { InteractionsService } from './interactions.service';
import { DateUtility } from 'app/common/date-utility';
import { Ajax } from 'app/common/ajax';
import { Helpers } from '@mi-tool/utils/helpers';
import { ErrorMessages } from '@mi-tool/enums/error.enum';
import { ApolloQueryResult } from '@apollo/client';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { toNumber } from 'lodash';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SingleInteractionService {
  readonly interaction = new Subject<MInteraction.BInteraction>();
  readonly relatedContentInteractionResult = new Subject<MInteraction.BInteraction>();
  readonly permissionDenied = new Subject<boolean>();
  readonly permissionForRelatedInteractionDenied$ = new Subject<boolean>();
  readonly interactionStatuses = new BehaviorSubject<string[]>([]);
  readonly latestInteraction = new BehaviorSubject<MInteraction.BInteraction>(undefined);

  private refreshLockTime: Date;

  constructor(
    private helpers: Helpers,
    private apollo: Apollo,
    private messageService: MessageHandlerService
  ) {
    this.refreshLockTime = DateUtility.newDate(-5000);
    this.interaction.subscribe((interaction) => this.latestInteraction.next(interaction));
  }

  /**
   * Lock the possibility to refresh the interaction data, this must be used when interactive processes are on going, otherwise data can change and consistency is not guaranteed.
   */
  lock() {
    this.refreshLockTime = new Date();
  }

  unlock() {
    this.refreshLockTime = new Date(-5000);
  }

  private isRefreshLocked(): boolean {
    return DateUtility.hasElapsed(this.refreshLockTime, 3000);
  }

  private _genericQueryWithId(query: any, pk: string, background?: boolean): any {
    if (background == undefined) {
      background = false;
    }
    return this.apollo.query({
      query: query,
      variables: {
        id: pk,
        'X-NO-LOADING': background,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });
  }

  private _singleQuery(pk: string, background?: boolean): any {
    return this._genericQueryWithId(singleInteractionQuery, pk, background);
  }

  performQuery(pk: string, background?: boolean) {
    console.log('refreshing lock', this.isRefreshLocked());
    if (this.isRefreshLocked()) {
      return;
    }
    this._singleQuery(pk, background).subscribe(
      (response) => {
        if (this.isPermissionDenied(response)) {
          this.permissionDenied.next(true);
          const pendingChanges = this.getPendingChangesData(response);
          this.interaction.next(
            new MInteraction.BInteraction(pendingChanges ? { pendingChanges } : '')
          );
        } else {
          this.interaction.next(new MInteraction.BInteraction(response.data['interaction']));
        }
      },
      (response, operation) => {
        console.error('Error', response, operation);
        // this.exceptionService.errorStream.next("(Interaction New) " + error);
      }
    );
  }

  private getPendingChangesData(response: ApolloQueryResult<BInteraction>): string {
    if (response.errors[0].message.includes(' | ')) {
      const messageParts = response.errors[0].message.split(' | ');
      let pendingChangesObject = JSON.parse(messageParts[1]);
      pendingChangesObject.interactionId = toNumber(messageParts[2]);
      return JSON.stringify(pendingChangesObject);
    }
    return '';
  }

  performQueryNew(pk: string, background?: boolean) {
    if (this.isRefreshLocked()) {
      return;
    }
    let queryNew = this._genericQueryWithId(singleNewInteractionQuery, pk, background);
    queryNew.subscribe(
      (response) => {
        this.interaction.next(new MInteraction.BInteraction(response.data['newInteraction']));
      },
      (error) => {
        this.messageService.error('(Interaction New) ' + error);
      }
    );
  }

  performQueryWithID(id: number, background?: boolean) {
    const pk = this.formatInteractionId(id, false);
    this.performQuery(pk, background);
  }

  performQueryNewWithID(id: number, background?: boolean) {
    const pk = this.formatInteractionId(id, true);
    this.performQueryNew(pk, background);
  }

  formatInteractionId(id: number, isDraft: boolean): string {
    const nodeType = isDraft ? 'NewInteractionNode' : 'InteractionNode';
    return pkToBase64(nodeType, id);
  }

  refreshInteraction(pk: string, background?: boolean) {
    if (base64ToModel(pk) == 'NewInteractionNode') {
      this.performQueryNew(pk, background);
    } else {
      this.performQuery(pk, background);
    }
  }

  performRelatedInteractionQueryWithId(id: number, background?: boolean) {
    if (this.isRefreshLocked()) {
      return;
    }
    const pk = this.formatInteractionId(id, false);
    this._singleQuery(pk, background).subscribe(
      (response) => {
        if (this.isPermissionDenied(response)) {
          this.permissionForRelatedInteractionDenied$.next(true);
        }
        this.relatedContentInteractionResult.next(
          new MInteraction.BInteraction(response.data['interaction'])
        );
      },
      (error) => {
        this.messageService.error('(Interaction performRelatedInteractionQuery) ' + error);
      }
    );
  }

  getMetadataValidationMessage(interaction: BInteraction): string {
    let inquiriesMessage = '';
    let inactiveItemsCount = 0;

    interaction.inquiries.forEach((inquiry, questionIndex) => {
      const inactiveTopic = inquiry.topic && !inquiry.topic.isActive;
      const inactiveCategory = inquiry.category && !inquiry.category.isActive;
      const inactiveProduct = inquiry.product && !inquiry.product.isActive;

      const inactiveTopicValue = inactiveTopic && 'Topic "' + inquiry.topic.name + '"';
      const inactiveCategoryValue = inactiveCategory && 'Category "' + inquiry.category.name + '"';
      const inactiveProductValue = inactiveProduct && 'Product "' + inquiry.product.name + '"';

      const deactivatedValuesArray = [
        inactiveTopicValue,
        inactiveCategoryValue,
        inactiveProductValue,
      ].filter(Boolean);
      if (deactivatedValuesArray.length === 0) {
        return;
      }
      inactiveItemsCount += deactivatedValuesArray.length;

      let deactivatedMetadataItemsArray = [];
      let isTopicProductSpecific = () =>
        inquiry.topic?.isProductSpecific && inquiry.product ? ['Topic', 'Category', 'Product'] : [];

      if (inactiveTopic || inactiveCategory) {
        deactivatedMetadataItemsArray.push(...['Topic', 'Category'], ...isTopicProductSpecific());
      }
      if (inactiveProduct) {
        deactivatedMetadataItemsArray.push(...['Product'], ...isTopicProductSpecific());
      }

      const deactivatedValuesString = this.helpers.arrayOfItemsToText(
        deactivatedValuesArray,
        false
      );
      const beginInquiriesMessage = `${
        interaction.inquiries.length > 1 ? 'Question ' + (questionIndex + 1) + ' - ' : ''
      }<strong>${deactivatedValuesString}</strong>`;
      const endInquiriesMessage =
        'Please select a new ' +
        this.helpers.arrayOfItemsToText([...new Set(deactivatedMetadataItemsArray)]);

      inquiriesMessage += `${beginInquiriesMessage} - ${endInquiriesMessage}<br>`;
    });
    if (inquiriesMessage) {
      const infoMessageText = `The following item${
        inactiveItemsCount === 1 ? ' is' : 's are'
      } deactivated and no longer available for selection, therefore deselected.<br>`;
      return infoMessageText + inquiriesMessage;
    }
    return '';
  }

  private isPermissionDenied(responseData: ApolloQueryResult<BInteraction>): boolean {
    return Boolean(
      responseData?.errors &&
        Array.isArray(responseData.errors) &&
        responseData.errors.some((error) =>
          error?.message?.startsWith(ErrorMessages.PermissionDenied)
        )
    );
  }
}

@Injectable({ providedIn: 'root' })
export class EditInteractionService {
  response: Subject<BInteraction> = new Subject<BInteraction>();
  // to post and retrieve just the minimum data needed for interaction, and to avoid refresh of the entrie object in all components
  minimalInteractionResponse: Subject<BInteraction> = new Subject<BInteraction>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  editInteractionMinimal(toMutate: Object, background?: boolean) {
    this.mutateWithQuery(toMutate, updateInteractionForAnswer, background).subscribe(
      (response) => {
        if (response) {
          this.minimalInteractionResponse.next(this.parseInteraction(response, background));
        } else {
          this.minimalInteractionResponse.next(undefined);
          this.messageService.error('Interaction Edit: empty response');
        }
      },
      (error) => {
        this.messageService.error('(Interaction Edit) ' + error);
      }
    );
  }

  editInteraction(toMutate: Object, background?: boolean): Subject<BInteraction> {
    const result = new Subject<BInteraction>();
    this.mutateWithQuery(toMutate, updateInteraction, background).subscribe(
      (response) => {
        if (response) {
          const interaction = this.parseInteraction(response, background);
          this.response.next(interaction);
          result.next(interaction);
        } else {
          this.response.next(undefined);
          this.messageService.error('Interaction Edit: empty response');
        }
      },
      (error) => {
        this.messageService.error('(Interaction Edit) ' + error);
      }
    );
    return result;
  }

  private mutateWithQuery(toMutate: Object, query: any, background?: boolean) {
    if (background == undefined) {
      background = false;
    }
    let mutationData = {};
    mutationData = Object.assign({}, toMutate);
    mutationData['clientMutationId'] = createGuid();
    return this.apollo.mutate({
      mutation: query,
      variables: { params: mutationData, 'X-NO-LOADING': background },
    });
  }

  private parseInteraction(response: any, background: boolean): BInteraction {
    if (!background) {
      this.messageService.info('Interaction updated correctly.');
    }
    const data = response.data.updateInteraction;
    const field = data.interaction ? 'interaction' : 'newInteraction';
    return parseAttr<BInteraction>(data, BInteraction, field);
  }
}

@Injectable({ providedIn: 'root' })
export class InteractionActionService {
  readonly availableActions = new BehaviorSubject<string[]>([]);
  private readonly URL = apiUrl('interactions', 'actions');

  constructor(
    private http: HttpClient,
    private singleInteractionService: SingleInteractionService,
    private messageService: MessageHandlerService,
    private genericDialog: GenericDialogService
  ) {}

  getAvailableActionsForObj(objId: string) {
    if (!objId) {
      console.warn('No object id passed for fetching actions, no sense to cause ISE on the server');
      return;
    }
    this.http
      .get<string[]>(this.URL, { params: { id: objId }, headers: Ajax.X_NO_LOADING_HEADERS })
      .subscribe((response) => {
        this.availableActions.next(response);
      });
  }

  performAction(data: any, retrieveInteraction: boolean = true): Observable<any> {
    const actionResponse = new Subject<any>();
    this.http.post(this.URL, data).subscribe({
      next: (response) => {
        if (response['success']) {
          actionResponse.next(response);
          if (retrieveInteraction) {
            // if the response is correct, retrieve the updated interaction
            this.singleInteractionService.unlock();
            this.singleInteractionService.performQuery(data.object_id);
          }
        } else {
          this.genericDialog.openDialogError('Error', response['error']);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.error('An error occurred during the execution of the action.');
        const displayError = (error.status === 409 && error.error['detail']) || error.error;
        this.genericDialog.openDialogError('Error', displayError);
        actionResponse.next(error);
      },
    });
    return actionResponse;
  }
}

@Injectable({ providedIn: 'root' })
export class InteractionWatchersService {
  constructor(private http: HttpClient) {}

  getInteractionWatchers(interactionId: number): Observable<{ watchersIds: number[] }> {
    return this.http.get<{ watchersIds: number[] }>(
      apiUrl('interactions', interactionId, 'watchers'),
      {}
    );
  }

  setInteractionWatchers(interactionId: number, watchersIds: number[]): Observable<void> {
    return this.http.put<void>(apiUrl('interactions', interactionId, 'watchers'), {
      watchersIds,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class InteractionAdditionalCasesService {
  constructor(private http: HttpClient) {}

  getAdditionalCases(interaction: BInteraction): Observable<BAdditionalCase[]> {
    return this.http
      .get<BAdditionalCase[]>(apiUrl('interactions', interaction.pk(), 'additional-cases'), {
        params: { isDraft: interaction.isNew() },
      })
      .pipe(map(BAdditionalCase.fromRestArray));
  }

  editAdditionalCases(
    interactionId: number,
    additionalCaseData: BAdditionalCase
  ): Observable<void> {
    return this.http.put<void>(
      apiUrl('interactions', interactionId, 'additional-cases'),
      additionalCaseData
    );
  }

  createAdditionalCases(
    interactionId: number,
    additionalCaseData: BAdditionalCase
  ): Observable<void> {
    return this.http.post<void>(
      apiUrl('interactions', interactionId, 'additional-cases'),
      additionalCaseData
    );
  }
}

export class BAdditionalCase {
  id: number;
  inquiryId?: number;
  newInquiryId?: number;
  interactionId?: number;
  newInteractionId?: number;
  externalRefId: string;
  externalRefSource: string | number;
  externalRefType?: string;
  departmentRefName?: string;

  static fromRest(json: any): BAdditionalCase {
    return Object.assign(new BAdditionalCase(), json);
  }

  static fromRestArray(json: any[]): BAdditionalCase[] {
    return json && json.map((v) => BAdditionalCase.fromRest(v));
  }
}

export class KeepAliveLockExecutor extends PeriodicalExecutor {
  interactionId: string;

  constructor(private inquiryDetail: InteractionsService) {
    super();
    this.interval = environment.keepAliveInterval;
  }

  execute() {
    if (this.executeData()) {
      this.inquiryDetail.keepAlive(this.executeData()).subscribe();
    }
  }
}
