export const hindiLangData = {
  MEDICAL_INFO_SYSTEM: 'चिकित्सा सूचना प्रणाली',
  ACTIVE_EVENTS: 'सक्रिय घटनाएँ',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'उपयोगकर्ताओं को ब्राउज़ करने के लिए एक घटना का चयन करें।',
  BACK_TO_THE_EVENT_SELECTION: 'इवेंट चयन पर वापस',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'अगर आप अपना पासवर्ड भूल गए हैं',
  LOGIN: 'लॉग इन करें',
  RESET_IT_HERE: 'इसे यहाँ रीसेट करें',
  CHANGE_LANGUAGE_TO: 'भाषा बदलें',
  TEST_SYSTEM: 'परीक्षण प्रणाली',
  HELP: 'मदद',
  THIS_IS_TEST_ENV: 'यह एक परीक्षण वातावरण है, यदि आप चाहते हैं कि वास्तविक जांच सम्मिलित हो',
  USER_OR_PASS_IS_INCORRECT: 'उपयोगकर्ता नाम या पासवर्ड गलत है',
  OPS: 'ऑप्स',
  WE_DID_NOT_FIND: 'हमें वह नहीं मिला, जिसकी आपको तलाश थी',
  VIDEO_TUTORIALS: 'वीडियो शिक्षण',
  STILL_DIDNT_FIND: 'अभी भी नहीं मिला कि तुम क्या देख रहे हो,',
  ASK_THE_SUPPORT_TEAM: 'सहायता टीम से पूछें',
  LOGGED_IN_AS: 'के रूप में लॉग इन किया',
  MY_PROFILE: 'मेरी प्रोफाइल',
  LOGOUT: 'लॉग आउट',
  RETRIEVING_YOUR_ACCESS_INFO: 'आपकी पहुंच की जानकारी प्राप्त करना',
  LOGGED_IN_CAN_CLOSE: 'लॉग इन करके अब आप इस टैब को बंद कर सकते हैं',
  TOKEN_HAS_NOT_BEEN_SAVED: 'टोकन को ठीक से सहेजा नहीं गया है, कृपया समर्थन से संपर्क करें',
  PASSWORD_RESET: 'पासवर्ड रीसेट',
  RESET_PASSWORD: 'पासवर्ड रीसेट',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'कृपया अपना पासवर्ड रीसेट करने के लिए अपना ईमेल डालें',
  CANCEL: 'रद्द करना',
  CANCEL_UPPERCASE: 'रद्द करना',
  RETURN_TO_LOGIN: 'लॉगिन पर लौटें',
  USERNAME: 'उपयोगकर्ता नाम',
  USERNAME_IS_REQUIRED: 'उपयोगकर्ता नाम आवश्यक है',
  PASSWORD: 'कुंजिका',
  PASSWORD_IS_REQUIRED: 'पासवर्ड की आवश्यकता है',
  FIRST_NAME: 'पहला नाम',
  LAST_NAME: 'अंतिम नाम',
  REGISTER: 'रजिस्टर करें',
  LOADING: 'लोड हो रहा है',
  INSERT_NEW_PASS: 'कृपया अपना नया पासवर्ड डालें',
  VERIFYING_TOKEN: 'टोकन का सत्यापन',
  PASSWORD_DO_NOT_MATCH: 'पासवर्ड मेल नहीं खाते',
  PASS_RESET_SUCCESSFUL: 'पासवर्ड रीसेट सफल',
  SAVE_NEW_PASS: 'नया पासवर्ड सेव करें',
  GO_TO_INBOX: 'इनबॉक्स में जाएं',
  SELECT_ALL: 'सभी का चयन करे',
  DESELECT_ALL: 'सभी को अचिन्हिंत करें',
  DO_NOT_SHOW_AGAIN: 'फिर से मत दिखाना',
  INQUIRY: 'जांच',
  ATTACHMENT_WITH_THIS_INQUIRY: '> इस पूछताछ के साथ अटैचमेंट',
  USER_DETAILS: 'उपभोक्ता का विवरण',
  CLOSE: 'बंद करे',
  TEAMS: 'टीमें',
  TEAM: 'टीम',
  SPECIALIZATION_IS: 'विशेषज्ञता है',
  REQUIRED: 'अपेक्षित',
  RESULTS_PER_PAGE: 'परिणाम प्रति पृष्ठ',
  RESULTS_FOUND: 'परिणाम मिले',
  EXPORT: 'निर्यात',
  PERSONAL_SETTINGS: 'व्यक्तिगत सेटिंग',
  TEAM_SETTINGS: 'टीम सेटिंग्स',
  USER_SETTINGS_OF: 'USER SETTINGS OF',
  SET_TEMPLATE: 'टेम्पलेट सेट करें',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'सूचनाओं को वर्तमान के साथ अधिलेखित कर दिया जाएगा',
  PREVIEW: 'पूर्वावलोकन',
  NOTIFICATIONS: 'सूचनाएं',
  ROLE: 'भूमिका',
  TEMPLATE_NAME: 'टेम्पलेट नाम',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'इस उपयोगकर्ता को टेम्पलेट के रूप में सहेजें',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'अपने उपयोगकर्ता के लिए एक टेम्पलेट जोड़ें और सूचनाओं के लिए भूमिका और वरीयताओं को कॉपी करें',
  AUTH_AND_NOTIFY_TEMPLATE: 'प्राधिकरण और अधिसूचनाएं टेम्पलेट',
  MY_ROLES: 'मेरी भूमिकाएँ',
  STATUS: 'स्थिति',
  THIS_ROLE_IS_TEMP:
    'यह भूमिका अस्थायी है, यह इस तथ्य के कारण दिया गया है कि यह उपयोगकर्ता एक डिप्टी है',
  CREATED: 'बनाया था',
  APPROVED_REJECTED: 'स्वीकृत / अस्वीकृत',
  APPROVED_REJECTED_BY: 'द्वारा अनुमोदित / अस्वीकृत',
  ARCHIVE: 'पुरालेख',
  SAVE: 'सहेजें',
  IMMEDIATE_EFFECT: 'तत्काल प्रभाव',
  OUT_OF_OFFICE_DEPUTY: 'कार्यालय के बाहर',
  AUTO_ASSIGN_TO: 'ऑटो ASSIGN TO',
  GEOGRAPHY: 'भूगोल',
  ALIGNMENT: 'संरेखण',
  Alignments: 'संरेखण',
  Address: 'पता',
  PHONE_FAX: 'फोन / फैक्स',
  Email: 'ईमेल',
  Contacts: 'संपर्क',
  WORK_INFO: 'काम की जानकारी',
  LAST_NAME_IS: 'आखिरी नाम है',
  PERSONAL_INFO: 'व्यक्तिगत जानकारी',
  USER: 'उपयोगकर्ता',
  ID: 'आईडी',
  UPDATED_FROM: 'से अपडेट किया गया',
  ON: 'पर',
  CRM: 'सीआरएम',
  IMPORTED_FROM: 'से आयात किया गया',
  CHANGE_PASS: 'पासवर्ड बदलें',
  NOTIFICATION_IN_TEMPLATE: 'खाका में सूचनाएं',
  CONTINUE: 'जारी रखें',
  DONT_SHOW_AGAIN: 'इस संदेश को फिर से न दिखाएं',
  USING_THE_TABS_ON_THE: 'स्क्रीन के शीर्ष पर टैब का उपयोग करना',
  PERSONAL_PROFILE_SETTINGS: 'व्यक्तिगत प्रोफ़ाइल सेटिंग्स',
  AND_YOUR: 'और आपका',
  YOU_CAN_SWITCH_BETWEEN: 'आप अपने बीच स्विच कर सकते हैं',
  WELCOME_TO_YOUR_USER_PROFILE: 'अपने उपयोगकर्ता प्रोफ़ाइल में आपका स्वागत है',
  SAVE_THIS_USER_AS: 'इस उपयोगकर्ता को टेम्पलेट के रूप में सहेजें',
  INCLUDE_NOTIFY_SETTINGS: 'अधिसूचना सेटिंग्स शामिल हैं',
  INCLUDE_ROLES_SETTINGS: 'इसमें रोल्स सेटिंग्स शामिल हैं',
  ACTIVE_NOTIFICATIONS: 'सक्रिय सूचनाएं',
  SET_THIS_USER_AS_A_: 'इस उपयोगकर्ता प्राधिकरण और अधिसूचना को नए टेम्पलेट के रूप में सेट करें',
  REQUEST_AUTH: 'अनुरोध प्राधिकरण',
  REQUEST: 'निवेदन',
  FEEDBACK_SUPPORT: 'प्रतिक्रिया और समर्थन',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'यदि आपको अभी भी वह नहीं मिल रहा है जिसकी आपको तलाश है, तो सहायता टीम से पूछें! इसके अलावा अगर आपके पास भविष्य में सुधार के लिए कोई सुझाव है तो यह सही जगह है।',
  TEMPLATE: 'खाका',
  SIGNATURE: 'हस्ताक्षर',
  Disclaimers: 'अस्वीकरण',
  Greetings: 'अभिवादन',
  CUSTOMIZE: 'अनुकूलित करें',
  SAVE_UPPERCASE: 'सहेजें',
  DELETE: 'हटाएँ',
  RESET_TO_DEFAULT: 'डिफ़ॉल्ट पर रीसेट',
  AFTER_SIGNATURE: 'हस्ताक्षर के बाद',
  BEFORE_SIGNATURE: 'हस्ताक्षर से पहले',
  AFTER_ANSWER: 'उत्तर के बाद',
  BEFORE_ANSWER: 'उत्तर देने से पहले',
  AFTER_GREET: 'अभिवादन के बाद',
  BEFORE_GREET: 'अभिवादन से पहले',
  Any: 'कोई भी',
  HCP: 'HCP',
  NO_HCP: 'कोई एच.सी.पी.',
  Position: 'पद',
  ON_LABEL: 'लेबल पर',
  OFF_LABEL: 'नामपत्र बंद',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'आप टेम्पलेट को कस्टमाइज़ करने की कोशिश कर रहे हैं',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'आपको इसे करने की अनुमति नहीं है। यदि आप आगे बढ़ना चाहते हैं, तो टेम्पलेट की एक प्रति बनाई जाएगी और आप वहां बदलाव करेंगे।',
  CREATE_COPY: 'प्रतिलिपि बनाएँ',
  RECEIVED_ON: 'प्राप्त हुआ',
  SENDER_EMAIL: 'प्रेषक ई-मेल',
  SUBJECT: 'विषय',
  UNSPAM: 'Unspam',
  UPLOAD: 'डालना',
  APPROVED: 'मंजूर की',
  DRAFT: 'प्रारूप',
  SELECT_STATUS: 'स्थिति का चयन करें',
  UPLOAD_AS: 'इस प्रकार अपलोड करें',
  TITLE: 'शीर्षक',
  REPOSITORY: 'कोष',
  BROWSE: 'ब्राउज़',
  URL: 'यूआरएल',
  APPROVED_ON: 'पर स्वीकृत किया गया',
  AUTH_HOLDER: 'प्राधिकरण धारक',
  AUTH_NUMBERS: 'प्राधिकरण संख्या',
  APPROVED_FOR: 'के लिए स्वीकृत किया गया',
  SELECT_ONE_REGION: 'एक क्षेत्र का चयन करें',
  OWNED_BY: 'के स्वामित्व',
  KEYWORDS: 'कीवर्ड',
  TOPICS: 'विषय',
  CATEGORIES: 'श्रेणियाँ',
  PRODUCTS: 'उत्पाद',
  PRODUCT: 'उत्पाद',
  LANGUAGE_IS_REQUIRED: 'भाषा की आवश्यकता है',
  LANGUAGE: 'भाषा: हिन्दी',
  TARGET_CUSTOMERS: 'लक्षित ग्राहकों',
  TYPE_IS_REQUIRED: 'प्रकार की आवश्यकता है',
  TYPE: 'प्रकार',
  TITLE_IS_REQUIRED: 'शीर्षक आवश्यक है',
  DEFAULT_REFERENCE_IS_CREATED_:
    'डिफ़ॉल्ट संदर्भ वैंकूवर शैली के साथ बनाया गया है। यदि आप इसे ओवरराइड करना चाहते हैं, तो यहां संपूर्ण संदर्भ दर्ज करें',
  REFERENCE: 'संदर्भ',
  DOI: 'DOI',
  PUBMED_ID: 'Pubmed ID',
  PAGES: 'पेज',
  ISSUE: 'मुद्दा',
  VOLUME: 'आयतन',
  JOURNAL: 'पत्रिका',
  AUTHORS: 'लेखक',
  ABSTRACT: 'सार',
  REFERENCE_IS_REQUIRED: 'संदर्भ की आवश्यकता है',
  THIS_DOC_IS_APPROVED: 'यह दस्तावेज़ स्वीकृत है। खोलने के लिए यहां क्लिक करें',
  ORIGINAL_DOCUMENT_AND_: 'मूल दस्तावेज़ और अपनी टिप्पणी डालें',
  EDIT_FILE: 'फ़ाइल संपादित करें',
  PROPERTIES: 'गुण',
  OPEN: 'खुला हुआ',
  LOCALIZED_DOCUMENTS: 'स्थानीयकृत दस्तावेज़',
  ORIGINAL_DOCUMENTS: 'मूल दस्तावेज़',
  INTERNAL_APPROVAL_NUMBER: 'आंतरिक अनुमोदन संख्या',
  CREATED_BY: 'के द्वारा बनाई गई',
  EXPIRATION_DATE: 'समाप्ति तिथि',
  VERSION_NUMBER: 'संस्करण संख्या',
  NO_VERSION_AVAILABLE: 'कोई संस्करण उपलब्ध नहीं है',
  ARCHIVED_IF_NO_DRAFT: 'संग्रहीत: यदि कोई ड्राफ्ट / स्वीकृत संस्करण मौजूद नहीं है।',
  APPROVED_FOR_USERS: 'स्वीकृत: उपयोगकर्ताओं के लिए।',
  DRAFT_FOR_AUTHORS_: 'ड्राफ्ट: लेखकों और संपादकों के लिए।',
  VERSION_LEGEND: 'संस्करण किंवदंती',
  VERSION: 'संस्करण',
  SELECT_ONE_LANG: 'एक भाषा का चयन करें।',
  SELECT_ONE_TYPE: 'एक प्रकार का चयन करें।',
  INTERNAL_NUMBER_REQUIRED: 'आंतरिक अनुमोदन संख्या आवश्यक है।',
  FILE: 'फ़ाइल',
  ATTACH_FILE: 'फ़ाइल जोड़ें',
  LITERATURE: 'साहित्य',
  SLIDE_DECK: 'सरकाने का पटाव',
  PIL: 'जनहित याचिका',
  FAQ: 'सामान्य प्रश्न',
  SRD: 'एस आर डी',
  ADD_APPROVAL_STEP: 'अनुमोदन कदम जोड़ें',
  ADD_PARALLEL_STEP: 'समानांतर चरण जोड़ें',
  DELETE_STEP: 'स्टेप डिलीट करें',
  DELETE_ALL_PARALLEL_STEPS: 'सभी समानांतर चरणों को हटाएं',
  CHANGE_STATUS_NAME: 'स्थिति नाम बदलें',
  SET_REQUIRED_ROLE: 'आवश्यक भूमिका निर्धारित करें',
  SET_TEAM_MEMBER: 'टीम का सदस्य सेट करें',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'आपके पास इस प्रक्रिया को बदलने की अनुमति नहीं है।',
  SAVE_ROLE: 'बचो',
  CREATE_NEW_WORKFLOW: 'नया काम बनाते हैं',
  CREATE_NEW_STEP: 'नया कदम बनाएँ',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'कृपया समानांतर अनुमोदन चरणों की संख्या दर्ज करें, जिसका अर्थ है कि एक ही समय में स्वीकृत कदम (क्रमिक रूप से नहीं)। यदि आपके पास कोई समानांतर अनुमोदन चरण नहीं है, तो दर्ज करें 1. आप हमेशा बाद में अनुमोदन चरणों की संख्या बदल सकते हैं।',
  APPROVAL_MUST_BE_AT_LEAST: 'अनुमोदन कम से कम होना चाहिए',
  INSERT_THE_NEW_STATUS_NAME: 'नया स्टेटस नाम डालें',
  INSERT_THE_NEW_WORKFLOW_NAME: 'नया वर्कफ़्लो नाम डालें।',
  INSERT_THE_NEW_STEP_NAME: 'नया स्टेप नाम डालें।',
  NEW_DOCUMENT: 'नया दस्तावेज़',
  CREATE: 'सृजन करना',
  DOCUMENT_MANAGEMENT: 'दस्तावेज़ प्रबंधन',
  CONFIRM: 'पुष्टि करें',
  CONFIRM_PROCESS: 'CONFIRM प्रक्रिया',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINE APPROVAL STEPS और TEAM',
  SET_DETAILS: 'विवरण सेट करें',
  SELECT_TYPE: 'प्रकार चुनें',
  COMPLETE_LOCALIZATION_PROCESS: 'पूर्ण स्थानीयकरण प्रक्रिया',
  CREATE_NEW_VERSION: 'नया संस्करण बनाएँ',
  CREATE_NEW_DOCUMENT: 'नया दस्तावेज़ बनाएँ',
  IS_PRIVATE: 'प्राइवेट है',
  SHARED_WITH_OTHER_TEAMS: 'अन्य टीमों के साथ साझा किया',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'कृपया आवश्यक अनुक्रमिक चरणों की संख्या दर्ज करें। यदि दो अनुमोदन समानांतर में हैं, तो यह एक चरण के रूप में गिना जाता है। आप बाद में अनुमोदन चरणों की संख्या को हमेशा बदल सकते हैं।',
  DONE: 'किया हुआ',
  SUBMIT_FOR_APPROVAL: 'अनुमोदन के लिए सबमिट करें',
  YOU_HAVE_TO_CONFIRM_THE_: 'आपको अपलोड से पहले प्रक्रिया की पुष्टि करनी होगी।',
  SELECT_YOUR_DEC_TYPE: 'अपने दस्तावेज़ प्रकार का चयन करें',
  TARGET_AUDIENCE: 'लक्षित दर्शक',
  PROCESS_DETAILS: 'प्रक्रिया विवरण',
  START_DATE: 'आरंभ करने की तिथि',
  DOC_DETAILS: 'दस्तावेज़ विवरण',
  CONFIRMATION: 'पुष्टीकरण',
  APPROVAL_PROCESS: 'अनुमोदन प्रक्रिया',
  SCHEDULED_EXP_DATE: 'निर्धारित समय सीमा समाप्ति तिथि',
  SCHEDULED_PUBLISHING_DATE: 'अनुसूचित प्रकाशन तिथि',
  NO_PROCESS_SELECTED: 'कोई प्रक्रिया नहीं चुनी गई',
  COUNTRIES: 'देश',
  DOC_ID: 'दस्तावेज़ आईडी',
  THERAPEUTIC_AREA: 'चिकित्सीय क्षेत्र',
  FILTER: 'फ़िल्टर',
  BACK: 'वापस',
  ATTACH: 'संलग्न करें',
  COPY_ANSWER: 'उत्तर की प्रतिलिपि बनाएँ',
  SIMILAR_INQUIRIES: 'इसी तरह की पूछताछ',
  SDR_FAQ: 'एसआरडी / एफएक्यू',
  SLIDE_DECKS: 'स्लाइड डेक',
  MEDICAL_DOC: 'चिकित्सा दस्तावेज',
  SHOW: 'प्रदर्शन',
  LOAD_MORE: 'और लोड करें',
  NO_RESULT_FOUND: 'कोई परिणाम नहीं मिला।',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'पूरे वाक्य की खोज के लिए इस्तेमाल किया जा सकता है, - निर्दिष्ट शब्द के भीतर खोज परिणामों से बाहर करने के लिए इस्तेमाल किया जा सकता है',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'चिकित्सा दस्तावेजों या इसी तरह की पूछताछ के लिए खोजें',
  SEARCH: 'खोज',
  Preview: 'पूर्वावलोकन',
  PREVIEW_AND_SEND: 'पूर्वावलोकन करें और भेजें',
  PREVIEW_AS_PDF: 'पीडीएफ के रूप में पूर्वावलोकन करें',
  PUBLISHED_VERSION: 'प्रकाशित संस्करण',
  NEW_VERSION: 'नया संस्करण',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'दस्तावेज़ अभी भी अनुमोदन में है, यदि आप दस्तावेज़ की सामग्री को किसी अन्य फ़ाइल को अपलोड करना चाहते हैं।',
  OUT_TO: 'बहार से',
  NEXT: 'आगे',
  SELECTED_SLIDES: 'चयनित स्लाइड्स',
  GO_TO_SLIDE: 'स्लाइड करने के लिए जाओ',
  PREV: 'पिछला',
  SELECT_SLIDE: 'स्लाइड का चयन करें',
  ANSWER: 'उत्तर',
  BACK_TO_STANDARD_VIEW: 'मानक दृश्य पर वापस जाएं।',
  UPLOAD_NEW_FILE: 'नई फ़ाइल अपलोड करें',
  CREATE_FILE: 'सृजन करें',
  EDITING_WORD_DOC: 'एडिटिंग वर्ड डॉक्यूमेंट',
  INSERT_FILE_NAME: 'फ़ाइल का नाम डालें',
  PROCEED_WITH_THE_LINK_: 'फ़ाइल के लिंक के साथ आगे बढ़ें',
  A_NEW_WINDOW_WILL_BE_: 'खाली फ़ाइल के साथ एक नई विंडो खोली जाएगी, सामग्री लिखें और टैब बंद करें',
  INSERT_THE_NAME_FOR_:
    'आप जिस फ़ाइल को बनाना चाहते हैं, उसके लिए नाम डालें और फ़ाइल निर्माण के साथ आगे बढ़ें',
  INSTRUCTIONS: 'अनुदेश',
  CREATE_NEW_WORD_FILE_FOR: 'के लिए नई शब्द फ़ाइल बनाएँ',
  FROM: 'से',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'आप यहां अंतिम जांच कर सकते हैं जो आपने सिस्टम में डाली है और सबमिट नहीं की है। आप अधिक समान पूछताछ बनाने के लिए आपके द्वारा डाली गई पूछताछ का पुन: उपयोग कर सकते हैं या पूछताछ में वापस आ सकते हैं।',
  MY_DRAFT_INQ: 'मेरा मसौदा पूछताछ',
  WARNING_CHANGES_NOT_SAVED: 'चेतावनी: परिवर्तन सहेजे नहीं गए',
  PAGE_THAT_WILL_BE_LOST: 'यदि आप जारी रखने का निर्णय लेते हैं तो पृष्ठ खो जाएगा।',
  YOU_HAVE_UNUSED: 'आपने में परिवर्तन नहीं किए हैं',
  ARE_YOU_SURE_YOU_WANT_TO_: 'क्या आप वाकई इस पृष्ठ को बिना सहेजे छोड़ना चाहते हैं?',
  LEAVE_THIS_PAGE: 'इस पृष्ठ को छोड़ो',
  STAY_ON: 'लगे रहो',
  ASSIGNED_TO: 'को सौंपना',
  THIS_INQ_HAS_BEEND_INSERTED_: 'यह पूछताछ घटना के दौरान डाली गई है।',
  STATUS_IN_APPROVAL: 'स्थिति: अनुमोदन में',
  STATUS_DRAFT: 'स्थिति: ड्राफ्ट',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'यदि आप वास्तविक जांच बनाना चाहते हैं, तो टैब सबमिट करें और क्रिएट बटन पर क्लिक करें।',
  INQ_IMPORTED_FROM: 'से इंक्वायरी की गई',
  SEND_NEW_ANSWER: 'नई उत्तर भेजें',
  RETURN_TO_INBOX: 'इनबॉक्स को रिटर्न्स',
  SUBMITTED_FOR_APPROVAL: 'अनुमोदन के लिए प्रस्तुत',
  ANSWER_SUBMITTED_ON: 'उत्तर प्रस्तुत किया गया',
  BY: 'द्वारा',
  INQ_HAS_BEEN_CLOSED: 'जवाब के बिना पूछताछ बंद कर दी गई है।',
  BCC: 'गुप्त प्रतिलिपि',
  ANSWER_GIVEN_ON: 'उत्तर दिया गया',
  ANSWER_SENT_ON: 'उत्तर भेजा गया',
  INBOX: 'इनबॉक्स',
  OPEN_ORIGINAL_INQ: 'मूल पूछताछ खोलें',
  CLOSE_ANSWER_NOT_NEEDED: 'बंद करें: आवश्यकता नहीं है',
  HISTORY: 'इतिहास',
  ADD_A_NOTE: 'टिप्पणी जोड़ें',
  ADD_A_NOTE_INTO_: 'जांच इतिहास में एक नोट जोड़ें।',
  SHARE_OR_START_A_FOLLOW_UP: 'शेयर करें या फॉलो अप शुरू करें',
  SEE_ALL_INQ_RELATED_TO: 'से संबंधित सभी पूछताछ देखें',
  SRD_NEEDED: 'एसआरडी की जरूरत',
  ANSWER_UNAVAILABLE: 'उत्तर अनुपलब्ध',
  QUESTION: 'सवाल',
  SUBMIT: 'प्रस्तुत',
  ANSWER_UPPERCASE: 'उत्तर',
  DETAILS: 'विवरण',
  SPLIT_INQ: 'स्प्लिट इंक्वायरी',
  ADD_QUESTION: 'प्रश्न जोड़ें',
  ENTER_QUESTION: 'प्रश्न दर्ज करें',
  MARK_AS_ANSWERED: 'उत्तर के रूप में चिह्नित करें',
  TO_BE_ANSWERED: 'उत्तर दिया जाना',
  FOLLOW_UP_RECEIVED: 'प्राप्त का पालन करें',
  ERROR: 'त्रुटि',
  SHOW_ORIGINAL_MESSAGE: 'मूल संदेश दिखाएं',
  SEND: 'संदेश',
  DO_NO_SEND: 'मत भेजो',
  OK: 'ठीक है',
  MAX_LENGTH_FOR_ZIP_CODE: 'ज़िप कोड के लिए अधिकतम लंबाई है',
  CHARACTERS: 'पात्र',
  ADDRESS_1_IS_: 'पता 1 है',
  LABEL_IS: 'लेबल है',
  NO_QUESTION_INSERTED: 'कोई प्रश्न नहीं डाला गया',
  WARNING: 'चेतावनी',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'इस इंटरैक्शन के लिए अनुवर्ती उपलब्ध नहीं है।',
  INSERT_INQ_TEXT: 'जांच पाठ डालें',
  SHOW_MORE: 'और दिखाओ',
  OUT_OF: 'से बाहर',
  SHARE: 'शेयर',
  HCP_PROFILE_OF: 'के एचसीपी शख्सियत',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'नीचे सभी अनिवार्य फ़ील्ड भरें और कम से कम एक ईमेल पता भरें और फिर जीडीपीआर रसीद अधिसूचना का पूर्वावलोकन करने के लिए सहेजें।',
  CONFIRMATION_SENT_BY: 'द्वारा भेजी गई अधिसूचना',
  COUNTRY_IS: 'देश है',
  COUNTRY: 'देश',
  AT_LEAST_ONE_CONTACT_IS_: 'कम से कम एक संपर्क है',
  EMAIL_IS: 'ईमेल है',
  EMAIL_LABEL_IS: 'ईमेल लेबल है',
  PHONE_FAX_IS: 'फोन / फैक्स लेबल है',
  PHONE_FAX_NUMBER_IS: 'फोन / फैक्स नंबर है',
  ADDRESS: 'पता',
  DELETE_PERSONAL_DATA: 'व्यक्तिगत डेटा हटाएं',
  HCP_VERIFIED: 'एचसीपी ने सत्यापित किया',
  KEY_OPINION_LEADER: 'प्रमुख राय नेता',
  HOSPITAL_BASED: 'अस्पताल आधारित',
  HCP_RELATED_INFORMATION: 'HCP संबंधित जानकारी',
  ACCEPT: 'स्वीकार करना',
  REJECT: 'अस्वीकार',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'क्या आप इन सुझावों को स्वीकार करना चाहते हैं?',
  SALES_REP: 'बिक्री प्रतिनिधि',
  SUGGEST_A_TOPIC: 'एक विषय का सुझाव दें',
  TEAM_SPECIFIC_FIELDS: 'टीम विशिष्ट क्षेत्र',
  ADR_RELATED: 'ADR संबंधी',
  NOT_ADR_RELATED: 'ADR संबंधित नहीं',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'कृपया संदेश की समीक्षा करें और उसे संबंधित दवा प्रतिक्रिया के रूप में वर्गीकृत करें या नहीं।',
  ADVERSE_DRUG_REACTION: 'प्रतिकूल दवा प्रतिक्रिया',
  CHANGE_DETAILS: 'विवरण बदलें',
  YES: 'हाँ',
  NO: 'नहीं',
  DUE_DATE: 'नियत तारीख',
  CRITICAL: 'नाजुक',
  NORMAL: 'साधारण',
  PRIORITY: 'प्राथमिकता',
  SELECT_DETAILS: 'विवरण का चयन करें',
  SAVE_REVIEW: 'समीक्षा सहेजें',
  SET_TO_ALL_INQ: 'सभी जांच सेट करें',
  PRODUCT_QUALITY_COMPLAINT: 'उत्पाद की गुणवत्ता की शिकायत',
  PQC_PC_A_PRODUCT_:
    'PQC / PC A उत्पाद गुणवत्ता शिकायत (QC) या समस्या को किसी भी दवा उत्पाद के दोष या खराबी को शामिल करने या किसी भी दवा उत्पाद की गुणवत्ता, प्रदर्शन, सुरक्षा के संबंध में किसी भी चिंता के रूप में परिभाषित किया गया है। बदली हुई गंध और / या स्वाद, खराब या दोषपूर्ण पैकेजिंग, संदिग्ध संदूषण, आदि।',
  OFF_LABEL_USE_OF_PHARMA___:
    'एक अप्रकाशित संकेत के लिए या एक अप्रयुक्त आयु समूह, खुराक, या प्रशासन के मार्ग के लिए दवा दवाओं का ऑफ-लेबल उपयोग।',
  A_RESPONSE_TO_A_MEDICINAL_:
    'एक औषधीय उत्पाद की प्रतिक्रिया जो एक औषधीय उत्पाद और एक प्रतिकूल घटना के बीच नाजायज और अनपेक्षित और कारण संबंध है, कम से कम एक उचित संभावना है। इसके अलावा विशेष स्थिति के मामलों (जैसे गर्भावस्था के मामलों, लेबल उपयोग, ओवरडोज) को एडीआर के रूप में चिह्नित करने की आवश्यकता है।',
  NORMAL_IS_7_WORKING_DAYS:
    'सामान्य 7 कार्य दिवसों है, उच्च 48 घंटे है और क्रिटिकल (रोगी संबंधी) 24 घंटे है',
  CATEGORY_AND_TOPIC_ARE: 'श्रेणी और विषय हैं',
  PRODUCT_IS: 'उत्पाद है',
  DO_YOU_WANT_TO_ACCEPT_: 'क्या आप इन सुझावों को स्वीकार करना चाहते हैं?',
  MERGE: 'मर्ज',
  Duplicate: 'डुप्लिकेट',
  Other: 'अन्य',
  Spam: 'स्पैम',
  CLOSE_INTERACTION: 'बातचीत बंद करें',
  RE_OPEN_THE_TARGET_: 'मर्ज के बाद लक्ष्य जांच को फिर से खोलें',
  MARK_AS_INTERNAL_: 'मौजूदा जांच के लिए एक आंतरिक संदेश के रूप में चिह्नित करें।',
  MARK_AS_AN_ERROR_: 'मौजूदा जांच के लिए एक त्रुटि संदेश के रूप में चिह्नित करें।',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'यह संदेश एक मौजूदा जांच की प्रतिक्रिया के रूप में चिह्नित किया जाएगा।',
  THANK_YOU: 'धन्यवाद',
  AN_INTERNAL_MESSAGE: 'एक आंतरिक संदेश',
  AN_ERROR_MESSAGE: 'एक त्रुटि संदेश',
  A_FOLLOW_UP_RESPONSE: 'अनुवर्ती प्रतिक्रिया',
  THIS_INQ_IS: 'यह पूछताछ है',
  SPLIT_INTERACTION: 'स्प्लिट इंटरेक्शन',
  SAVE_AND_CLOSE: 'सहेजें और बंद करें',
  SAVE_INQ: 'पूछताछ सहेजें',
  REVIEW_THE_TEXT_AND: 'पाठ और वर्गीकरण की समीक्षा करें, और पुष्टि करें',
  CHOOSE_THE_RELEVANT_PRODUCT: 'चयनित पाठ के लिए प्रासंगिक उत्पाद, श्रेणी और विषय चुनें',
  SELECT_THE_TEXT_THAT_:
    'एक उत्पाद / विषय के बारे में प्रश्न की पहचान करने वाले पाठ का चयन करें (अभिवादन, हस्ताक्षर, अस्वीकरण, या अन्य गैर-प्रासंगिक जानकारी शामिल न करें)',
  REPEAT_THE_PREVIOUS_: 'अधिक पूछताछ जोड़ने के लिए पिछले चरणों को दोहराएं',
  READ_MORE: 'अधिक पढ़ें',
  READ_LESS: 'कम पढ़ें',
  DO_NOT_INCLUDE_: 'अभिवादन, हस्ताक्षर, अस्वीकरण, या अन्य गैर-प्रासंगिक जानकारी शामिल न करें',
  SELECT_THE_TEXT_THAT_IDENT:
    'एक उत्पाद / विषय के बारे में प्रश्न की पहचान करने वाले पाठ का चयन करें',
  IF_YOU_HAVE_MULTIPLE_: 'यदि आपके पास कई उत्पाद या विषय हैं, तो जांच को विभाजित करना होगा।',
  THE_FOLLOWING_PROCESS_:
    'निम्नलिखित प्रक्रिया आपको प्रत्येक उत्पाद / विषय के लिए प्रासंगिक पाठ की पहचान करने में मदद करेगी: ',
  EXPORT_TO_PDF: 'पीडीएफ को निर्यात करें',
  Added: 'जोड़ा गया',
  Deleted: 'हटाए गए',
  From: 'से',
  To: 'सेवा',
  BACK_TO_INBOX: 'इनबॉक्स में वापस',
  PERMISSION_DENIED: 'अनुमति नहीं मिली',
  THIS_INQ_IS_LOCKED_BY: 'यह पूछताछ बंद है',
  FORCE_UNLOCK: 'बल प्रयोग',
  INQUIRIES: 'पूछताछ',
  Download: 'डाउनलोड',
  Event: 'प्रतिस्पर्धा',
  INSERTED_BY: 'द्वारा डाला गया',
  LAST_EDITED_ON: 'अंतिम बार संपादित',
  LAST_EDITED_BY: 'अंतिम बार संपादित',
  CREATED_ON: 'को निर्मित',
  TOPIC: 'विषय',
  TABLE_OPTIONS: 'तालिका विकल्प',
  DEPARTMENT: 'विभाग',
  User: 'उपयोगकर्ता',
  ADD_THERAPEUTIC_AREA: 'चिकित्सीय क्षेत्र जोड़ें',
  CREATE_THERAPEUTIC_AREA: 'नया चिकित्सीय क्षेत्र बनाएं',
  ADD_NEW: 'नया जोड़ें',
  SELECT_TEAM_TO_PROCEED: 'आगे बढ़ने के लिए टीम का चयन करें',
  MIM: 'एमआईएम',
  MA: 'एमए',
  MSL: 'एम एस एल',
  SALES: 'बिक्री',
  CALL_CENTER: 'कॉल सेंटर',
  ALL: 'सब',
  Warning: 'चेतावनी',
  clear: 'स्पष्ट',
  THESE_CHANGES_WILL_BE_: 'ये परिवर्तन चयनित उपयोगकर्ताओं पर लागू होंगे',
  INSERT_A_FOLLOW_UP_RESP: 'अनुवर्ती प्रतिक्रिया का पालन करें',
  INSERT_A_NOTE: 'एक नोट डालें',
  MERGED_FROM: 'से विलय कर दिया',
  CHANGES: 'परिवर्तन',
  SHOW_LESS: 'कम दिखाएं',
  COMPLETE: 'पूर्ण',
  MISSING: 'लापता',
  CLOSE_AS_FORWARDED: 'फॉरवर्ड के रूप में बंद',
  CLOSE_EXT_TEAM: 'बाहरी टीम को भेजे गए पूछताछ को बंद करें',
  THE_INQ_BELONG_TO_A_:
    'यह जांच एक ऐसे देश की है जो एक बाहरी टीम की जिम्मेदारी है। फिर जांच को इस टीम को स्वचालित रूप से भेज दिया जाएगा।',
  RESOLVE: 'हल',
  TERRITORIES: 'प्रदेशों',
  EXTERNAL_COMMUNICATION: 'बाहरी संचार',
  FOR_INQ_COMING_DIRECTLY_:
    'ईमेल के माध्यम से सीधे एचसीपी से आने वाली पूछताछ के लिए, स्वचालित रूप से एक सूचना भेजने के लिए उसे सूचित करें कि पूछताछ प्राप्त हुई है।',
  INTERNAL_COMMUNICATION: 'आंतरिक संवाद',
  SALESREP_RECEIVES_: 'Salesrep को केवल सूचनाएं प्राप्त होती हैं, जिनका उत्तर दिया गया था',
  SALESREP_RECEIVES_FULL_ANSWER_: 'सेल्सरेप को लेबल पूछताछ के लिए पूर्ण उत्तर प्राप्त होता है',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Salesrep को केवल सूचनाएं प्राप्त होती हैं, जिनका उत्तर दिया गया था',
  Name: 'नाम',
  Module: 'मापांक',
  Function: 'समारोह',
  LAST_RUN: 'आखरी बार',
  End: 'समाप्त',
  Every: 'प्रत्येक',
  Period: 'अवधि',
  Active: 'सक्रिय',
  Resolved: 'हल',
  ERROR_PREVIEW: 'त्रुटि पूर्वावलोकन',
  FAILURE_COUNT: 'असफलता की गिनती',
  COMPLETED_ON: 'पर पूर्ण',
  STARTED_ON: 'को प्रारंभ करें',
  TASK_NAME: 'कार्य का नाम',
  PRODUCT_SPECIFIC: 'उत्पाद विनिर्देश',
  THERAPEUTIC_AREAS: 'सैद्धांतिक क्षेत्र',
  PRODUCT_GROUPS: 'उत्पाद विकास',
  LICENSING_PARTNER: 'लिंटरिंग पार्टनर',
  PRODUCT_CODE: 'उत्पाद कोड',
  EXTERNAL_ID: 'बाहरी आईडी',
  OBJECT_TYPE: 'वस्तु प्रकार',
  MIGRATION_NAME: 'प्रवासन का नाम',
  MIGRATION_ERRORS: 'प्रवासन त्रुटियां',
  ADD_SALES_REP: 'बिक्री प्रतिनिधि जोड़ें',
  ASSIGNED_TO_MSL: 'MSL से जुड़ा हुआ',
  NOT_ASSIGNED: 'सौंपा नहीं गया है',
  ASSIGNED_TO_OTHER_MSL: 'अन्य MSL को सौंपा',
  ASSIGNED_TO_THIS_MSL: 'इस MSL को सौंपा',
  SHOW_ONLY: 'केवल दिखाअो',
  SALES_REP_LIST: 'बिक्री प्रतिनिधि सूची',
  ACTIVE_TASK: 'कार्य को सक्रिय करें',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'उपयोगकर्ता चुनें और घटना के लिए पारी सेट करें',
  MANAGE_SHIFTS: 'मंच की शैलियाँ',
  CREATE_NEW_USER: 'नया उपयोगकर्ता बनाएँ',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'यह टेम्प्लेट स्वचालित रूप से आपकी टीम के सदस्य के साथ जुड़ जाएगा।',
  TEMPLATE_FOR: 'के लिए टेम्पलेट',
  Day: 'दिन',
  Week: 'सप्ताह',
  Month: 'महीना',
  Today: 'आज',
  Archive: 'पुरालेख',
  PARTICIPANTS: 'प्रतिभागियों',
  EVENT_MANAGEMENT: 'इवेंट मैनेजमेंट',
  ADD_TASK: 'टास्क जोड़ें',
  EDIT_TASK: 'कार्य संपादित करें',
  IS_ACTIVE: 'सक्रिय है',
  Months: 'महीने',
  Days: 'दिन',
  Hours: 'घंटे',
  Minutes: 'मिनट',
  HCP_ADMINISTRATION: 'एचसीपी प्रशासन',
  AUTH_REQ_ADMINISTRATION: 'प्रामाणिक अनुरोध प्रशासन',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'उत्पाद को चयनित चिकित्सीय क्षेत्रों में हाइलाइट किया जाएगा।',
  PRODUCT_GROUPS_DEFINE_:
    'उत्पाद समूह इस उत्पाद पूछताछ का जवाब देने के लिए जिम्मेदार टीम को परिभाषित करते हैं।',
  USER_ADMINISTRATION: 'उपयोगकर्ता प्रशासन',
  IS_PRODUCT_SPECIFIC: 'उत्पाद विशिष्ट है',
  USER_TEMPLATES_ADMIN: 'उपयोगकर्ता टेम्पलेट प्रशासन',
  SET_DEFAULT_VALUES: 'सेट वैल्यूज़ सेट करें',
  SET_DEFAULT_VALUES_FOR_: 'ईवेंट के लिए डिफ़ॉल्ट मान सेट करें',
  INQUIRY_DEFAULT_VAL: 'डिफ़ॉल्ट मानों की जाँच करें',
  CREATE_NEW_EVENT: 'नई घटना बनाएँ',
  EDIT_EVENT: 'संपादित करें',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'नई घटना बनाने के लिए विवरण सेट करें',
  ADD_DOC_LINKED_TO_: 'घटना से जुड़े दस्तावेज़ जोड़ें',
  INC_CHANNEL: 'इंक चैनल',
  CHANGE_DETAILS_OF_THE_EVENT: 'घटना का विवरण बदलें',
  DESCRIPTION: 'विवरण',
  CREATE_APPROVAL_PROCESS: 'स्वीकृति प्रक्रिया चुनें',
  CREATE_NEW: 'नया बनाओ',
  CUSTOMIZE_PROCESS: 'प्रक्रिया को अनुकूलित करें',
  SELECTED_PROCESS: 'चयनित प्रक्रिया',
  CLONE: 'क्लोन',
  PREFERENCES_TITLE: 'पसंद',
  NOTIFICATIONS_TITLE: 'सूचनाएं',
  TEMPLATES: 'टेम्पलेट्स',
  LETTER_TEMPLATES: 'पत्र टेम्पलेट  ',
  USER_TEMPLATES: 'उपयोगकर्ता टेम्पलेट',
  TEAM_MEMBERS: 'टीम का सदस्या',
  NEW_PASSWORD: 'नया पासवर्ड',
  REPEAT_NEW_PASSWORD: 'नया पासवर्ड दोहराएँ',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'कम से कम 1 निचला मामला पत्र',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'कम से कम 1 ऊपरी मामला पत्र',
  AT_LEAST_1_DIGIT: 'कम से कम 1 अंक',
  AT_LEAST_1_SPECIAL_CHARACTER: 'कम से कम 1 विशेष चरित्र',
  MINIMUM_8_CHARACTERS: 'न्यूनतम 8 वर्ण',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'ई-मेल या नाम नहीं होना चाहिए',
  SHOULD_MATCH_PASSWORD: 'पासवर्ड से मेल खाना चाहिए',
  USER_MANUAL: 'उपयोगकर्ता पुस्तिका',
  SUPPORT: 'सहयोग',
  BACK_TO_LOGIN: 'लॉगिन पर वापस जाएं',
  PASSWORD_CONFIRMATION: 'पासवर्ड पुष्टि',
  HAVE_AN_ACCOUNT: 'पहले से ही एक खाता है?',
  Submit: 'प्रस्तुत',
  Gender: 'लिंग',
  PROFESSION: 'व्यवसाय',
  MEDICAL_INQ_ROLES: 'चिकित्सा पूछताछ प्रसंस्करण भूमिका',
  MEDICAL_INFO_MANAGER: 'चिकित्सा सूचना प्रबंधक',
  MEDICAL_SCIENCE_LIAISON: 'चिकित्सा विज्ञान सम्पर्क',
  NOT_PROCESSING_INQUIRIES: 'प्रोसेसिंग इंक्वायरी नहीं',
  ACTIVE_HEALTH_CARE_SPEC: 'सक्रिय स्वास्थ्य देखभाल विशेषज्ञ',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'सक्रिय स्वास्थ्य देखभाल विशेषज्ञ नहीं',
  PASS_IS_REQUIRED: 'पासवर्ड की आवश्यकता है!',
  EMAIL_IS_REQUIRED: 'ईमेल जरुरी है!',
  VALID_EMAIL: 'कृपया एक वैध ई - मेल एड्रेस डालें!',
  FIELD_IS_REQUIRED: 'यह फ़ील्ड आवश्यक है!',
  ONLY_LETTERS: 'कृपया केवल पत्र दर्ज करें!',
  ARE_YOU_daiichi_EMPLOYEE: 'क्या आप दाइची सैंक्यो कर्मचारी हैं?',
  here: 'यहाँ',
  CREATE_AN_ACCOUNT: 'खाता बनाएं',
  FORGOT: 'भूल गया?',
  Medical: 'मेडिकल',
  Medical_not_resp: 'चिकित्सा - एमआईआर के जवाब देने के लिए जिम्मेदार नहीं है',
  Commercial: 'व्यावसायिक',
  Market_access: 'बाज़ार पहूंच',
  Pharmacovigilance: 'फामार्कोविजिलेंस',
  PR: 'पीआर',
  Please_specify: 'कृपया निर्दिष्ट करें',
  Medical_information_manager: 'चिकित्सा सूचना प्रबंधक',
  Medical_advisor: 'चिकित्सीय परामर्श',
  Are_Medical_inquiries: 'क्या आप चिकित्सा जांच का जवाब देने के लिए जिम्मेदार हैं?',
  Are_Health_care_professional: 'क्या आप एक स्वास्थ्य देखभाल पेशेवर हैं?',
  Health_care_Industry: 'स्वास्थ्य सेवा उद्योग',
  Health_Authority: 'स्वास्थ्य प्राधिकारी',
  Journalist: 'पत्रकार',
  Lawyer: 'वकील',
  Patient: 'मरीज़',
  Payor: 'payor',
  Scientist: 'वैज्ञानिक',
  Angiologist: 'Angiologist',
  Cardiologist: 'हृदय रोग विशेषज्ञ',
  Cardiovascular_Surgeon: 'कार्डियोवस्कुलर सर्जन',
  Dentist: 'दंत चिकित्सक',
  Gastroenterologist: 'जठरांत्र चिकित्सक',
  General_Practitioner: 'सामान्य चिकित्सक',
  Geriatrician: 'Geriatrician',
  Gynecologist: 'प्रसूतिशास्री',
  Haematologist: 'haematologist',
  Internist: 'इंटरनिस्ट',
  Medical_Student: 'मेडिकल छात्र',
  Nephrologist: 'किडनी रोग विशेषज्ञ',
  Neurologist: 'न्यूरोलॉजिस्ट',
  Neurosurgeon: 'न्यूरोसर्जन',
  Nurse: 'नर्स',
  Oncologist: 'ऑन्कोलॉजिस्ट',
  Oncology_Nurse: 'ऑन्कोलॉजी नर्स',
  Other_HCP: 'अन्य एच.सी.पी.',
  Other_Surgeon: 'अन्य सर्जन',
  Pediatrician: 'बच्चों का चिकित्सक',
  Pharmacist: 'फार्मेसिस्ट',
  Pharmacologist: 'औषध विज्ञानी',
  Pharmacy_Technician: 'फार्मेसी तकनीशियन',
  Pneumologist: 'Pneumologist',
  Radiologist: 'रेडियोलोकेशन करनेवाला',
  Rheumatologist: 'ह्रुमेटोलॉजिस्ट',
  USER_PASS_INCORRECT: 'उपयोगकर्ता नाम या पासवर्ड गलत है',
  SUBMITTED_REQUEST: 'आपका अनुरोध सफलतापूर्वक सबमिट किया गया',
  Reset: 'रीसेट',
  Reset_pass: 'पासवर्ड रीसेट',
  New_pass: 'नया पासवर्ड',
  RESET_PASS_EMAIL: 'आपके ईमेल पर एक रीसेट पासवर्ड लिंक भेजा गया था',
  NEW_PASS_SUCCESS: 'आपका पासवर्ड सफलतापूर्वक रीसेट कर दिया गया था',
  EVENTS: 'आयोजन',
  Login_here: 'यहां लॉगिन करें',
  REGISTRATION: 'पंजीकरण',
  Create_an_account: 'खाता बनाएं',
  NO_RECORDS: 'कोई रिकॉर्ड नहीं',
  INTERACTION: 'इंटरेक्शन',
  SPECIALIZATION: 'विशेषज्ञता',
  SPECIALIZATION_TYPE: 'विशेषज्ञता प्रकार',
  SAVED: 'बचाया',
  SAVING: 'सहेजा जा रहा है',
  TYPING: 'टाइपिंग',
  CREATE_NEW_INTERACTION: 'नई सहभागिता बनाएँ',
  FILTER_BY: 'के द्वारा छनित',
  SEND_EMAIL: 'ईमेल भेजें',
  SAVE_WITHOUT_EMAIL: 'ईमेल के बिना सहेजें',
  SUBMIT_TO_INBOX: 'इनबॉक्स में सबमिट करें',
  Create_my_acc: 'मेरा खाता बनाओ',
  NEW_QUESTION: 'नया प्रश्न',
  LABEL: 'लेबल',
  SELECT_A_LABEL: 'एक लेबल का चयन करें',
  CITY: 'Faridabad',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'एक नई श्रेणी का सुझाव दें या किसी मौजूदा का चयन करें',
  SUGGEST_CATEGORY_OR_TOPIC: 'नई श्रेणी या विषय का सुझाव दें',
  URGENT: 'अति आवश्यक',
  EXISTING_CATEGORY: 'मौजूदा श्रेणी',
  NEW_CATEGORY_NAME: 'नई श्रेणी का नाम',
  NEW_TOPIC_NAME: 'नया विषय',
  CATEGORY: 'वर्ग',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'निवर्तमान चैनल',
  LOGIN_TO_CONTINUE: 'Madjenta को जारी रखने के लिए लॉग इन करें',
  Email_address: 'ईमेल पता',
  FORGOT_PASS: 'पासवर्ड भूल गए?',
  Remember_me: 'मुझे याद रखना',
  YOU_WILL_BE_NOTIFIED: 'आपके पंजीकरण के लिए पुष्टिकरण ई-मेल को और निर्देशों के साथ भेजा गया था',
  SUCCESS_EMAIL_CONFIRMATION: 'आपके ई-मेल की सफलतापूर्वक पुष्टि हो गई।',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'जब आपके अनुरोध की समीक्षा की जाएगी तो आपको सूचित किया जाएगा',
  FAILED_EMAIL_CONFIRMATION: 'हम आपके ई-मेल की पुष्टि करने में सक्षम नहीं हैं।',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'कृपया पुनः प्रयास करें और यदि समस्या जारी रहती है तो समस्या के वर्णन के समर्थन में लिखना जारी रखें',
  RESET_FILTERS: 'फ़िल्टर रीसेट करें',
  NEW_PASS_RESET_SUCCESS: 'पासवर्ड रीसेट सफलतापूर्वक!',
  SOMETHING_WENT_WRONG: 'कुछ गलत हुआ, कृपया समर्थन से संपर्क करें।',
  Cardiovascular: 'कार्डियोवास्कुलर',
  Oncology: 'कैंसर विज्ञान',
  Due_On: 'पर कारण',
  Follow_Up_Sent_On: 'भेजे गए का पालन करें',
  Follow_Up_Received_On: 'प्राप्त पर का पालन करें',
  Closed_On: 'पर बंद हुआ',
  Reopened_On: 'पर फिर से खोल दिया',
  Inquiry_ID: 'पूछताछ आईडी',
  Search: 'खोज',
  Assigned_to_Now: '(अब) को सौंपा',
  Edited_By: 'द्वारा संपादित',
  Assigned_to_Any_Time: '(किसी भी समय) को सौंपा गया',
  Closed: 'बन्द है',
  Foreign: 'विदेशी',
  Complex_search_Sentence_: 'जटिल खोज: वाक्य: "वाक्य की खोज", नहीं: -शब्द',
  Search_inquiries: 'खोज खोज',
  SEARCH_REPOSITORY: 'रिपॉजिटरी खोजें',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'फिल्टर',
  Active_Filters: 'सक्रिय फिल्टर',
  CONTACT_SUPPORT: 'सहयोग टीम से संपर्क करें',
  MedicalInformationRequests: 'पूछताछ (पीडीएफ)',
  UserProfileNavigation: 'उपयोगकर्ता प्रोफ़ाइल (पीडीएफ)',
  UserManualChanges: 'उपयोगकर्ता मैनुअल परिवर्तन (docx)',
  iPad_How_To: 'iPad (PDF)',
  MedIS_Assign_Inquiry: 'जांच सौंपें',
  MedIS_Documentation_Approval_Process: 'प्रलेखन अनुमोदन प्रक्रिया',
  MedIS_Documentation_Overview: 'प्रलेखन अवलोकन',
  MedIS_Inquiry_Documentation: 'पूछताछ दस्तावेजीकरण',
  MedIS_MIR_Inbox_Filters: 'एमआईआर इनबॉक्स फिल्टर',
  MedIS_Merge_MIR: 'मिर्ज मीर',
  MedIS_New_Documentation: 'नया दस्तावेज़',
  MedIS_New_Inquiry: 'नई जांच',
  MedIS_Out_Of_Office: 'कार्यालय से बाहर',
  MedIS_Prepare_Custom_Answer: 'कस्टम उत्तर तैयार करें',
  MedIS_Split_MIR_Part_I: 'स्प्लिट MIR पार्ट I',
  MedIS_Split_MIR_Part_II: 'विभाजन एमआईआर भाग II',
  I_AGREE_TO: 'मैं सहमत हूँ',
  TermsAndConditionsLink: 'नियम और शर्तें',
  Title: 'शीर्षक',
  INTERACTION_TITLE: 'सहभागिता शीर्षक',
  Move: 'चाल',
  Left: 'बाएं',
  Right: 'सही',
  Requestor: 'निवेदन कर्ता',
  Assigned_To: 'को सौंपना',
  Status: 'स्थिति',
  Note: 'ध्यान दें',
  Confirm: 'पुष्टि करें',
  rejectConfirm: 'क्या आप वाकई अनुरोध को अस्वीकार करना चाहते हैं?',
  approveConfirm: 'क्या आप वाकई अनुरोध को स्वीकार करना चाहते हैं?',
  newUser: 'नया उपयोगकर्ता',
  editUser: 'यूजर को संपादित करो',
  deleteUser: 'उपभोक्ता मिटायें',
  newProduct: 'नया उत्पाद',
  editProduct: 'उत्पाद संपादित करें',
  deleteProduct: 'उत्पाद हटाएं',
  newCategory: 'नई श्रेणी',
  editCategory: 'श्रेणी संपादित करें',
  deleteCategory: 'श्रेणी हटाएँ',
  newTopic: 'नया विषय',
  editTopic: 'विषय संपादित करें',
  deleteTopic: 'विषय हटाएं',
  userRequest: 'उपयोगकर्ता अनुरोध',
  productRequest: 'उत्पाद का अनुरोध',
  categoryRequest: 'श्रेणी का अनुरोध',
  categoryTopicRequest: 'श्रेणी / विषय अनुरोध',
  topicRequest: 'विषय अनुरोध',
  Suggestion: 'सुझाव',
  tradeName: 'व्यापारिक नाम',
  activeSubstance: 'सक्रिय पदार्थ',
  productName: 'उत्पाद का नाम',
  productSpecific: 'क्या यह उत्पाद विशिष्ट है?',
  EMAIL_IN_USE: 'इस ई-मेल के साथ मौजूदा खाता है',
  fromDate: 'तारीख से',
  toDate: 'तारीख तक',
  applyFilter: 'फिल्टर लागू करें',
  requestReceivedFrom: 'से अनुरोध प्राप्त हुआ',
  on: 'पर',
  at: 'पर',
  to: 'सेवा',
  add: 'जोड़ना',
  from: 'से',
  approve: 'मंजूर',
  reject: 'अस्वीकार',
  withTheFollowingRole: 'निम्नलिखित भूमिका के साथ',
  forTheFollowingTeam: 'निम्नलिखित टीम के लिए',
  request: 'निवेदन',
  submittedBy: 'द्वारा प्रस्तुत',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'पासवर्ड रीसेट अनुरोध अमान्य या समाप्त हो गया है',
  PASS_IS_RECENT: 'पासवर्ड हाल ही में उपयोग किया गया है',
  Assigned_to_me: 'मुझे सौंपा गया',
  My_drafts: 'मेरा ड्राफ्ट',
  REPORTS: 'रिपोर्ट',
  Pending_approval: 'लंबित अनुमोदन',
  ADMINISTRATION: 'शासन प्रबंध',
  Show_all: 'सब दिखाओ',
  In_progress: 'चालू',
  Table_Settings_Columns: 'तालिका सेटिंग्स - कॉलम',
  Change_column_visibility: 'कॉलम दृश्यता बदलें',
  Reassign_to: 'को पुन: असाइन करें',
  set_priority: 'प्राथमिकता दर्ज करें',
  set_status: 'स्थिति सेट करें',
  Spam_list: 'स्पैम सूची',
  Institution: 'संस्थान',
  DUE_ON: 'पर कारण',
  Profile_settings: 'पार्श्वचित्र समायोजन',
  Preferences: 'पसंद',
  Role_settings: 'भूमिका सेटिंग्स',
  Notification_settings: 'अधिसूचना सेटिंग',
  User_profile: 'उपयोगकर्ता प्रोफ़ाइल',
  Select_person: 'व्यक्ति का चयन करें',
  FROM_DATE: 'तारीख से',
  TO_DATE: 'तारीख तक',
  JOB_DESC: 'नौकरी का विवरण',
  INQ_Management: 'पूछताछ प्रबंधन',
  DOCS_Management: 'दस्तावेज़ प्रबंधन',
  USER_Management: 'उपयोगकर्ता प्रबंधन',
  Define_Frequency: 'आवृत्ति को परिभाषित करें',
  All_teams_all_inq: 'सभी टीमों के लिए सभी पूछताछ',
  My_teams_all_inq: 'मेरी टीम के लिए सभी पूछताछ',
  Search_people_here: 'लोगों को यहां खोजें',
  Search_team_here: 'टीम यहां खोजें',
  people: 'लोग',
  teams: 'टीमें',
  empty: '(खाली)',
  can_not_be_assigned: 'इस उपयोगकर्ता की कोई टीम नहीं है और इसे असाइन नहीं किया जा सकता है',
  select_a_team: 'कृपया के लिए एक टीम का चयन करें',
  confirm_team_change: 'टीम परिवर्तन की पुष्टि करें',
  change_the_team_: 'क्या आप वाकई टीम को बदलना चाहते हैं?',
  can_not_see_it_anymore: 'आप इसे वर्तमान में नहीं देख पाएंगे',
  filtered_view: 'फ़िल्टर किया गया दृश्य।',
  PHONE: 'फ़ोन',
  FAX: 'फैक्स',
  LETTER: 'पत्र',
  F2F_SITE_VISIT: 'F2F (साइट पर जाएँ)',
  F2F_CONGRESS: 'F2F (कांग्रेस)',
  F2F_ADBOARD: 'F2F (एडबोर्ड)',
  F2F_OTHER: 'F2F (अन्य)',
  WEBINAR_SITE_VISIT: 'आभासी (साइट पर जाएँ)',
  WEBINAR_CONGRESS: 'वर्चुअल (कांग्रेस)',
  WEBINAR_ADBOARD: 'वर्चुअल (AdBoard)',
  WEBINAR_OTHER: 'आभासी (अन्य)',
  CHAT: 'चैट',
  COURIER: 'संदेशवाहक',
  REP_DELIVERY: 'प्रतिनिधि',
  SYSTEM: 'प्रणाली',
  WEB_FORM: 'वेब फार्म',
  Signature: 'हस्ताक्षर',
  Disclaimer: 'अस्वीकरण',
  STATE: 'राज्य',
  ADDRESS_1: 'पता 1',
  ADDRESS_2: 'पता द्वितीय',
  ZIP_CODE: 'पिन कोड',
  HIGH: 'उच्च',
  ADVANCED: 'उन्नत',
  CLICK_BELOW_TO_POPULATE_ANSWER: 'नीचे दिए गए पाठ और फ़ील्ड पर क्लिक करें और जवाब को संपादित करें',
  DOCUMENT_VIEW: 'दस्तावेज़ देखें',
  FORM_VIEW: 'प्रपत्र देखें',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'जवाब भेजने के लिए आपके पास अधिकार नहीं हैं।',
  ANSWER_TEXT: 'उत्तर पाठ',
  CONTACT_INFORMATION: 'संपर्क जानकारी',
  COMPANY: 'कंपनी',
  SRD_NEEDED_TOOLTIP: 'इस जांच के उत्तर के लिए नए SRD दस्तावेज़ की आवश्यकता है',
  ENABLE_ALL: 'सभी को सक्षम करें',
  DISABLE_ALL: 'सबको सक्षम कर दो',
  BEFORE: 'इससे पहले',
  AFTER: 'उपरांत',
  EDIT: 'संपादित करें',
  MY_TEMPLATES: 'मेरे टेम्प्लेट',
  TEAM_TEMPLATES: 'टीम टेम्प्लेट',
  TEAM_TEMPLATES_HINT: 'पूरी टीम के लिए मेडिकल सूचना प्रबंधक द्वारा बनाया गया टेम्प्लेट',
  SOURCE: 'स्रोत',
  TEMPLATE_NO_MATCH_INTERACTION: 'यह टेम्प्लेट इंटरैक्शन विवरण से मेल नहीं खाता है और लागू नहीं है',
  EMAIL_SUBJECT: 'ईमेल विषय',
  INQUIRY_SUBMIT_HINT: 'कृपया, जांच का उत्तर देने के लिए सभी आवश्यक फ़ील्ड भरें',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'समाप्ति तिथि के लिए अनुसूचित प्रकाशन',
  COPY_ATTACHMENT_TO_ANSWER: 'उत्तर के लिए प्रति संलग्न करें',
  COPY_TEXT_TO_ANSWER: 'उत्तर के लिए पाठ की प्रतिलिपि बनाएँ',
  SELECT_INQUIRY: 'जांच का चयन करें',
  EDIT_INQ: 'प्रश्न संपादित करें',
  SPLIT_ANOTHER: 'एक और विभाजन',
  DELETE_INQ: 'जांच हटाएं',
  SELECT_TEXT_FOR_SPLIT: 'उसी इंटरैक्शन में नया प्रश्न जोड़ें',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'अलग से बातचीत / मामला उत्पन्न करें',
  SPLIT_ORIGINAL_TEXT: 'मूल पाठ (विभाजन के लिए पाठ का चयन करें)',
  SPLIT_TEXT_TO_REMAIN_: 'प्रारंभिक पूछताछ में बने रहने के लिए पाठ',
  SPLIT_TEXT_TO_SPLIT_: 'पाठ विभाजित होना',
  SPLIT_SELECTED_SECTIONS: 'विभाजन के लिए चयनित अनुभाग',
  SPLIT_NO_MODIFICATIONS_HINT:
    'जैसे ही आप मूल पाठ से अनुभागों का चयन करेंगे, पाठ स्वचालित रूप से आबाद हो जाएगा',
  SPLIT_MODIFICATIONS_HINT:
    'पाठ को मैन्युअल रूप से संशोधित किया गया है और मूल पाठ से किसी भी आगे के चयन को लागू नहीं किया जाएगा।\n संशोधनों को छोड़ने के लिए यहां क्लिक करें।',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'जांच विवरण पर परिवर्तनों के साथ पाठ स्वचालित रूप से अपडेट किया जाएगा।',
  SELECT_DIFFERENT_TEMPLATE: 'एक अलग टेम्पलेट चुनें',
  APPROVE: 'मंजूर',
  LAST_EDIT_ON: 'अंतिम पर संपादित करें',
  LAST_EDIT_BY: 'अंतिम बार संपादित करें',
  INTERNAL_COMM: 'आंतरिक संवाद',
  INACTIVE: 'निष्क्रिय',
  DEACTIVATE: 'निष्क्रिय करें',
  ARCHIVED: 'संग्रहीत',
  REPUBLISH: 'पुनर्प्रकाशन',
  INTERACTION_TEMPLATE_HINT:
    'हस्ताक्षर, अभिवादन और अस्वीकरण ईमेल, पत्र और फैक्स जैसे लिखित संचार का हिस्सा हैं।\n उनका उपयोग करने के लिए, सुनिश्चित करें कि आउटगोइंग चैनल उनमें से किसी एक पर सेट है।',
  CONFIRM_AUTH_REQUEST: 'प्रामाणिक अनुरोध की पुष्टि करें',
  VIEW_AUTH_REQUEST: 'प्रामाणिक अनुरोध देखें',
  QUESTION_TITLE: 'प्रश्न शीर्षक: ',
  NO_TITLE_INQUIRY: '-no-\nशीर्षक\n-\nenquiry-',
  EXISTING_CATEGORIES: 'मौजूदा श्रेणियां',
  EXISTING_TOPICS: 'मौजूदा विषय',
  DO_NOT_ASSIGN_PRODUCT: 'उत्पाद असाइन न करें',
  QUESTION_TEXT: 'प्रश्न पाठ',
  DATE: 'दिनांक',
  REJECTED: 'अस्वीकृत',
  FILTER_AUTH_REQUESTS_BY: 'द्वारा निवेदन को फ़िल्टर करें',
  ALL_REQUESTS_ALL_TEAMS: 'सभी टीमों के लिए सभी अनुरोध',
  ALL_TEAMS: 'सभी टीमें',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'आपके पास श्रेणी / विषय अनुरोधों को संपादित करने के अधिकार नहीं हैं।',
  SEND_FOLLOW_UP: 'फॉलो-अप भेजें',
  RECEIPT_NOTIFICATION_CUSTOMER: 'ग्राहक को रसीद सूचना',
  GDPR_DISCLAIMER: 'जीडीपीआर अस्वीकरण',
  ACTIONS: 'कार्रवाई',
  SUBMISSION_CONDITIONS: 'प्रस्तुत करने की शर्तें',
  TYPE_OF_NOTIFICATION: 'अधिसूचना का प्रकार',
  DIRECT_SUBMISSION: 'डायरेक्ट सबमिशन',
  DIRECT_SUBMISSION_TOOLTIP:
    'ईमेल के माध्यम से ग्राहक से सीधे सबमिट की गई पूछताछ के लिए, ग्राहक को उनके व्यक्तिगत डेटा के संचालन के बारे में सूचित करने के लिए एक अधिसूचना भेजें।',
  COMPANY_EMPLOYEE_SUBMISSION: 'कंपनी कर्मचारी के माध्यम से जमा किया गया',
  ENABLE_NOTIFICATION: 'सूचनाएं सक्षम करें',
  NOTIFICATION_SENDER: 'प्रेषक को सूचना',
  SALES_REP_DELIVERED_RESPONSE: 'वितरित प्रतिक्रिया के लिए बिक्री प्रतिनिधि',
  NOTIFICATION_DELIVERED_RESPONSE: 'वितरित प्रतिक्रिया के लिए अधिसूचना',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'कंपनी प्रतिनिधि (बिक्री प्रतिनिधि, एमएसएल, या अन्य) के माध्यम से सबमिट की गई पूछताछ के लिए, ग्राहक को उनकी पूछताछ का उत्तर मिलने पर प्रेषक को सूचित करें',
  FULL_ANSWER: 'पूरा जवाब',
  NOTIFICATION: 'अधिसूचना',
  NAME: 'नाम',
  COLUMN_INIT_QUESTION_TEXT: 'मूल प्रश्न पाठ',
  NOTIFICATION_ENABLED: 'अधिसूचना सक्षम',
  DEFAULT_TEMPLATE: 'डिफ़ॉल्ट टेम्पलेट',
  SET_AS_DEFAULT: 'डिफाल्ट के रूप में सेट',
  DEFAULT_TEMPLATE_TOOLTIP: 'टॉगल अक्षम है क्योंकि कम से कम एक डिफ़ॉल्ट टेम्पलेट होना चाहिए!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'डिफ़ॉल्ट टेम्पलेट हटाया नहीं जा सकता!',
  BATCH_UPDATE_ONLY: 'सबसे ऊपरी चेकबॉक्स के माध्यम से केवल एक बैच अपडेट संभव है।',
  ACCESS_DENIED: 'पहुंच अस्वीकृत!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'आपको इस पृष्ठ तक पहुंचने की अनुमति नहीं है।',
  USER_ROLES: 'उपयोगकर्ता भूमिका',
  MANAGE_USER_ROLES: 'उपयोगकर्ता भूमिकाएं प्रबंधित करें',
  ARCHIVE_ROLE: 'पुरालेख भूमिका',
  ROLE_ARCHIVE_CONFIRMATION: 'क्या आप वाकई इस भूमिका को संग्रहित करना चाहते हैं?',
  ROLES_FOR: 'के लिए भूमिकाएँ',
  AUDIT_TRAIL: 'ऑडिट ट्रेल',
  ACTION_BY_USER: 'उपयोगकर्ता द्वारा कार्रवाई',
  ACTION: 'कार्रवाई',
  ORIGINAL_VALUE: 'मूल मान',
  CHANGE: 'बदलें',
  CONDITION: 'हालत',
  IP: 'आईपी',
  GEOLOCATION: 'जियोलोकेशन',
  NEW_VALUE: 'नया मान',
  FILTER_BY_ACTION: 'कार्रवाई के अनुसार फ़िल्टर करें',
  SELECT_DATE: 'तारीख चुनें',
  TEAM_SAVE: 'बचाना',
  TEAM_DELETE: 'मिटाना',
  TEAM_CREATE: 'सृजन करना',
  TEAM_CLONE: 'प्रतिलिपि',
  TEAM_NAME: 'नाम',
  TEAM_CODE: 'कोड',
  TEAM_ACTION_DELETE_CONFIRM: 'क्या आप वास्तव में हटाना चाहते हैं',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'ध्यान से!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'एक बार हटाए जाने के बाद, इसे बहाल नहीं किया जा सकता है!',
  TEAM_FULL_ANSWER_ON_LABEL: 'लेबल पर पूरा उत्तर',
  TEAM_RESTRICTED_TEAM: 'प्रतिबंधित',
  TEAM_SEGREGATED_DATA: 'अलग -अलग डेटा',
  TEAM_PRIVATE_TEAM: 'निजी टीम',
  TEAM_AUTO_ASSIGN: 'ऑटो आवंटित',
  TEAM_MAILBOX_IN: 'इनबाउंड मेलबॉक्स',
  TEAM_MAILBOX_OUT: 'आउटबाउंड मेलबॉक्स',
  TEAM_DEFAULT_LANGUAGE: 'डिफ़ॉल्ट भाषा',
  TEAM_COUNTRIES: 'सौंपे गए देश',
  TEAM_PRODUCT_GROUPS: 'उत्पाद समूह असाइन करें',
  TEAM_PARENT: 'अभिभावक टीम',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'ओवरराइड क्लस्टर रूट टीम',
  TEAM_AFFILIATIONS: 'संबद्ध टीम',
  TEAM_TIMEZONE: 'समय क्षेत्र',
  UPDATE: 'अद्यतन',
  AUTO_ASSIGN_BY: 'ऑटो द्वारा असाइन किया गया',
  AUTO_ASSIGNMENTS: 'ऑटो असाइनमेंट',
  NO_ONE: 'किसी को भी नहीं',
  NOT_APPLICABLE: 'लागू नहीं',
  OVERRIDE_NOTIFICATIONS: 'अधिसूचनाओं को ओवरराइड करना',
  OVERRIDE_NOTIFICATIONS_HINT:
    'जब ओवरराइड नोटिफिकेशन के साथ उपयोगकर्ता टेम्पलेट उपयोगकर्ता के लिए सेट होता है, तो यह किसी भी Preexisting अधिसूचना सेटिंग्स को अक्षम कर देगा और उपयोगकर्ता टेम्पलेट में सूचीबद्ध केवल अधिसूचना सेटिंग्स को सक्षम करेगा',
  CREATED_MANUALLY: 'मैन्युअल रूप से बनाया गया',
  DOCUMENT_TITLE_FOR_WORD:
    'वर्ड दस्तावेजों के लिए शीर्षक आमतौर पर दस्तावेज़ के भीतर शीर्षक स्वरूपित अनुभाग से लिया जाता है। यदि दस्तावेज़ में शीर्षक नहीं है, तो आप इसे यहां निर्दिष्ट कर सकते हैं। यदि आप यहां शीर्षक निर्दिष्ट नहीं करते हैं और वर्ड डॉक्यूमेंट में शीर्षक स्वरूपित अनुभाग नहीं है, तो दस्तावेज़ आईडी का उपयोग शीर्षक के लिए किया जाएगा।',
  ORDER_BY: 'द्वारा आदेश',
  FORMAT: 'प्रारूप',
  SELECT_QUESTION: 'प्रश्न का चयन करें',
  SELECT_QUESTION_FOR_NOTE: 'प्रश्न चुनें जिसके लिए आप नोट जोड़ना चाहते हैं',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'पूरी जांच के लिए नोट जोड़ें',
  REQUESTED_DATE: 'अनुरोधित दिनांक',
  UNASSIGNED: 'सौंपे नहीं गए',
  BLANK: 'खाली',
  ANSWER_SAVED_WITHOUT_EMAIL: 'ईमेल के बिना सहेजे गए उत्तर',
  INQ_RELATED_TO: 'से संबंधित पूछताछ',
  QUESTION_TEXT_BEFORE_SPLIT: 'विभाजन से पहले प्रश्न पाठ',
  SPLIT_MERGE: 'विभाजित और विलय',
  CLARIFICATION_QUESTION: 'स्पष्टीकरण प्रश्न',
  ACTIVE_ON: 'पर सक्रिय होना',
  DISMISS: 'नकार देना',
  GO_TO_ENQUIRY: 'पूछताछ करना',
  NO_TEAMS: 'बिना टीम के',
  NO_TEAMS_TIP:
    'इसमें उपयोगकर्ता शामिल हैं, जो किसी भी टीम को नहीं सौंपे जाते हैं। आप जांच सकते हैं कि क्या उनमें से कुछ आपकी टीम से हैं और उन्हें उचित भूमिका प्रदान करते हैं।',
  TASKS: 'कार्य',
  Therapeutic_Areas: 'चिकित्सीय क्षेत्र',
  Product_Groups: 'उत्पाद समूह',
  Licensing_Partner: 'लाइसेंसिंग पार्टनर',
  Product_Code: 'उत्पाद कोड',
  SELECT_EXISTING_ONE: 'मौजूदा एक का चयन करें',
  OR: 'या',
  MANAGE_FORM: 'रूप',
  EVENT_FORM_ADDRESS: 'घटना प्रपत्र पता',
  EVENT_FORM_PREVIEW_WARING:
    'सुनिश्चित करें कि आपने कांग्रेस के फॉर्म को खोलने या पते की नकल करने से पहले कोई बदलाव बचाया है',
  SEARCH_SAVED_SEARCHES: 'खोजे गए खोजों को खोजें',
  GO: 'जाओ',
  ASSIGN: 'सौंपना',
  CERTIFICATION: 'प्रमाणीकरण',
  SORT: 'क्रम से लगाना',
  ADD_MAILBOX: 'मेलबॉक्स जोड़ें',
  UNSET_INCOMING_MAILBOX: 'अस्वाभाविक आवक मेलबॉक्स',
  SET_INCOMING_MAILBOX: 'इनकमिंग मेलबॉक्स सेट करें',
  UNSET_OUTGOING_MAILBOX: 'अस्वाभाविक आउटगोइंग मेलबॉक्स',
  SET_OUTGOING_MAILBOX: 'आउटगोइंग मेलबॉक्स सेट करें',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'वर्तमान मेलबॉक्स के साथ सभी टीमें',
  ALL_TEAMS_WITH_MAILBOX: 'मेलबॉक्स के साथ सभी टीमें',
  MAILBOX: 'मेलबॉक्स',
  EMAIL_POLLING: 'ईमेल मतदान',
  ADVANCED_SEARCH: 'उन्नत खोज',
  SELECT_FIELDS: 'फ़ील्ड का चयन करें',
  SEARCH_TERM: 'शब्द को खोजें',
  COLUMN_INTERACTION_ID: 'बातचीत आईडी',
  COLUMN_QUESTION_ID: 'प्रश्न आईडी',
  COLUMN_CREATED_ON: 'पर बनाया',
  COLUMN_LAST_EDITED_DATE: 'अंतिम संपादित किया गया',
  COLUMN_ASSIGNED_TO: 'के लिए आवंटित',
  COLUMN_DUE_DATE: 'नियत तारीख',
  COLUMN_FOLLOW_SENT_DATE: 'अनुवर्ती भेजे गए तिथि',
  COLUMN_FOLLOW_RECEIVED_DATE: 'अनुवर्ती प्राप्त तिथि',
  COLUMN_ANSWER_DATE: 'उत्तर की तारीख',
  COLUMN_CLOSED_ON_DATE: 'तिथि पर बंद',
  COLUMN_REOPENED_DATE: 'पुन: खुला तिथि',
  COLUMN_INSERTED_BY: 'द्वारा डाला गया',
  COLUMN_LAST_EDITED_BY: 'द्वारा अंतिम संपादित किया गया',
  COLUMN_ANSWERED_BY: 'द्वारा उत्तर दिया',
  COLUMN_APPROVED_BY: 'द्वारा अनुमोदित',
  COLUMN_INT_COMM_USER: 'आंतरिक संचार उपयोगकर्ता',
  COLUMN_INT_COMM_DATE: 'आंतरिक संचार तिथि',
  COLUMN_MY_TEAM: 'मेरी टीम',
  COLUMN_PQC_RELATED: 'PQC संबंधित',
  COLUMN_INCOMING_CHANNEL: 'आवक चैनल',
  COLUMN_OUTGOING_CHANNEL: 'निवर्तमान चैनल',
  COLUMN_OFF_LABEL: 'नामपत्र बंद',
  COLUMN_HCP_SPEC: 'एचसीपी + विशेषज्ञता',
  COLUMN_NON_HCP_SPEC: 'गैर-एचसीपी + पेशे',
  COLUMN_KEE: 'कील',
  COLUMN_HOSPITAL_BASED: 'अस्पताल आधारित',
  COLUMN_CITY: 'शहर',
  COLUMN_INSTITUTION: 'संस्थान',
  COLUMN_TERRITORY: 'क्षेत्र',
  CASE_ID: 'मामले आईडी',
  OPTION_ADD: 'जोड़ें',
  OPTION_AND: 'तथा',
  OPTION_OR: 'या',
  OPTION_NOT: 'नहीं',
  OPTION_YES: 'हाँ',
  OPTION_NO: 'नहीं',
  QUERY: 'सवाल',
  EXPORT_LABEL: 'निर्यात स्तंभों को परिभाषित करें',
  SAVE_SEARCH: 'खोज संग्रहित करें',
  SHARE_SEARCH: 'शेयर खोज',
  SEARCH_BTN: 'खोज',
  BACK_ACTION: 'पीछे',
  SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
  SAVED_SEARCH: 'सहेजे गए खोज',
  NEW: 'नया',
  SAVE_AS: 'नाम के रूप में सहेजें',
  ENTER_RECIPIENTS: 'प्राप्तकर्ता दर्ज करें',
  CLEAR_SAVED_SEARCH: 'स्पष्ट खोजों को स्पष्ट करें',
  NO_SAVED_SEARCH: 'कोई बचाया खोज नहीं मिली',
  INT_COMM_TEXT: 'आंतरिक संचार पाठ',
  FOLLOW_UP_TEXT: 'अनुवर्ती पाठ',
  CONFIRM_DELETION: 'मिटाने की पुष्टि',
  ARE_SURE: 'क्या आप सुनिश्चित हैं कि आप सहेजे गए खोज को हटाना चाहते हैं',
  PERMANENTLY_DELETED: 'खोज स्थायी रूप से <b> हटा दी जाएगी </b>।',
  NOTHING_SAVE: 'बचाने के लिए कुछ भी नहीं।',
  NOTHING_SEARCH: 'खोज करने के लिए कुछ भी नहीं।',
  NOTHING_EXPORT: 'खोज के लिए निर्यात का कोई रिकॉर्ड नहीं है।',
  SHARE_SUCCESS: 'खोज को सफलतापूर्वक साझा किया गया है',
  SHARE_FAILURE: 'खोज साझा नहीं की जा सकती थी',
  DELETED_SUCCESS: 'सफलतापूर्वक बचाया खोज को हटा दिया गया',
  ALL_DELETED_SUCCESS: 'सफलतापूर्वक सभी सहेजे गए खोजों को हटा दिया गया',
  VIEW_SAVED_SEARCH: 'सेव की गई खोज देखें',
  EDIT_SAVED_SEARCH: 'सहेजे गए खोज को संपादित करें',
  EXPORT_SAVED_SEARCH: 'निर्यात सहेजे गए खोज',
  EXPORT_SEARCH: 'निर्यात खोज',
  DELETE_SAVED_SEARCH: 'सेव की गई खोज को हटाएं',
  SELECT_EXPORT_COLUMNS: 'निर्यात करने के लिए कॉलम का चयन करें',
  SAVED_SEARCH_ALREADY_EXIST: 'उस नाम के साथ खोजें पहले से मौजूद हैं।',
  CHARACTER_LIMIT: 'मिन 3 वर्ण। अधिकतम 35 वर्ण।',
  CHARACTER_LEFT: 'वर्ण छोड़ दिया जाता है',
  ACTIVATE: 'सक्रिय',
  INACTIVE_USER: 'यह उपयोगकर्ता अब सक्रिय नहीं है। कृपया अपने सिस्टम व्यवस्थापक से संपर्क करें।',
  FOLLOW_UP: 'आगे की कार्रवाई करना',
  USER_NOT_FOUND: 'उपयोगकर्ता नहीं मिला',
  DEPARTMENTS: 'विभागों',
  EMAIL: 'ईमेल',
  ADD_NEW_DEPARTMENT: 'नया विभाग जोड़ें',
  DEPARTMENT_COUNTRY: 'देश',
  DEPARTMENT_COMPANY: 'कंपनी',
  DEPARTMENT_TEAM: 'टीम',
  DEPARTMENT_TA: 'चिकित्सीय क्षेत्र',
  DEPARTMENT_PRODUCT: 'उत्पाद',
  DEPARTMENT_ACTIONS: 'कार्रवाई',
  DEPARTMENT_CONFIRMATION_1: 'मिटाने की पुष्टि?',
  DEPARTMENT_CONFIRMATION_2: 'यह कार्रवाई अपरिवर्तनीय है।',
  DEPARTMENT_NAME: 'विभाग का नाम',
  TYPE_DEPARTMENT: 'टाइप',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'देश - कृपया पहले टीम का चयन करें',
  DEP_NAME_CHARACTER_LIMIT: 'अधिकतम 200 वर्ण।',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'उपयोगकर्ता अनुरोध',
      CategoryTopicRequest: 'श्रेणी/विषय अनुरोध',
      ProductRequest: 'उत्पाद अनुरोध',
      UserUpdateRequest: 'उपयोगकर्ता अद्यतन अनुरोध',
    },
    TYPE: {
      NewUser: 'नया उपयोगकर्ता',
      NewCategoryTopic: 'नई श्रेणी/विषय',
      NewUserUpdate: 'उपयोगकर्ता अद्यतन',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'देश विशिष्ट विशेषज्ञता सूची को परिभाषित करें',
      IS_COUNTRY_SPECIFIC: 'देश विशिष्ट है',
      TYPE: 'पूछताछकर्ता प्रकार',
      SPECIALIZATION_EXISTS:
        '"पूछताछकर्ता प्रकार/विशेषज्ञता" के लिए कॉन्फ़िगरेशन पहले से मौजूद है।',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'लिंग', COUNTRY: 'देश', TITLE: 'शीर्षक' },
  },
  hcp: 'एचसीपी',
  emp: 'कर्मचारी',
  cons: 'गैर-एचसीपी',
  OOO_DEPUTY: 'ओओओ डिप्टी',
  NO_DEPUTY: 'कोई डिप्टी',
  UNSPECIFIED_END_DATE: 'अनिर्दिष्ट अंत तिथि',
  DELEGATORS: 'प्रतिनिधि',
  NO_DELEGATORS: 'कोई प्रतिनिधि नहीं',
  PREVIEW_DELEGATORS: 'पूर्वावलोकन प्रतिनिधि',
  REMOVE_DEPUTY: 'डिप्टी को हटा दें',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'उपयोगकर्ता जो आपको डिप्टी के रूप में सेट करते हैं',
  CHANGED_USER: 'परिवर्तित उपयोगकर्ता',
  DEFAULT: 'चूक',
  SPECIALIZATIONS: 'विशेषज्ञता',
  ENQUIRERS_LIST: 'संलग्नक सूची',
  EDIT_THE_RECORD: 'रिकॉर्ड संपादित करें',
  DEACTIVATE_THE_RECORD: 'रिकॉर्ड को निष्क्रिय करें',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'कार्यालय से बाहर',
      HINT: 'केवल सक्रिय उपयोगकर्ता, जांच प्रसंस्करण भूमिका के साथ, जो कार्यालय से बाहर नहीं हैं, चयन के लिए उपलब्ध हैं।',
      MODAL_TITLE: 'कार्यालय सेटिंग्स से बाहर की पुष्टि करें',
      MODAL_MSG_1:
        'आपने तत्काल प्रभाव के साथ कार्यालय विकल्प से बाहर कर दिया है, आपकी सभी पूछताछ तुरंत डिप्टी को सौंपी जा रही है।',
      MODAL_MSG_2:
        'आपने कार्यालय विकल्प से बाहर कर दिया है, आपकी सभी पूछताछ कार्यालय की अवधि के दौरान डिप्टी को सौंपी जा रही है।',
    },
  },
  GENDER_MALE: 'श्री।',
  GENDER_FEMALE: 'श्रीमती।',
  TITLE_DR: 'डॉ।',
  TITLE_PHD: 'पीएचडी',
  TITLE_PROF: 'प्रो',
  TITLE_PROF_DR: 'प्रोफेसर डॉ.',
  HOME: 'घर',
  HOSPITAL: 'अस्पताल',
  OFFICE: 'कार्यालय',
  WORK: 'काम',
  INSERT_IMAGE: 'चित्र डालें',
  USER_ADMIN: 'उपयोगकर्ता व्यवस्थापक',
  MAILBOXES: 'मेलबॉक्स',
  AUTHORIZATION_REQUESTS: 'प्राधिकरण अनुरोध',
  METADATA: 'मेटाडाटा',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'कार्यभार',
      FORWARD: 'आगे',
      FOLLOW_UP: 'आगे की कार्रवाई करना',
      DUE_DATE_SETTINGS: 'नियत तारीख सेटिंग्स',
      CLOSE: 'बंद करना',
      DELIVERY_ERROR: 'वितरण त्रुटि',
      SCHEDULER: 'समयबद्धक',
      USER_REPORTS: 'उपयोगकर्ता रिपोर्ट',
      DOCUMENT_APPROVAL: 'दस्तावेज़ स्वीकृति',
      AUTHORIZATION_REQUEST: 'प्राधिकरण अनुरोध',
      NEW_INQUIRY: 'नई पूछताछ',
      INQUIRY_APPROVAL: 'पूछताछ अनुमोदन',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'एक भूमिका अनुरोध को मंजूरी दी गई/अस्वीकार कर दिया गया',
      AUTHORIZATION_REQUEST_3: 'एक भूमिका मेरी स्वीकृति की प्रतीक्षा कर रही है',
      DOCUMENT_APPROVAL_1: 'एक दस्तावेज आपकी मंजूरी का इंतजार कर रहा है',
      DOCUMENT_APPROVAL_2: 'एक दस्तावेज को मंजूरी दी गई/अस्वीकार कर दिया गया',
      DOCUMENT_APPROVAL_3: 'एक दस्तावेज संग्रहीत किया गया था',
      DOCUMENT_APPROVAL_4: 'परिवर्तनों के साथ एक दस्तावेज को मंजूरी दी गई थी',
      DOCUMENT_APPROVAL_5: 'एक दस्तावेज में संग्रहीत किया जाएगा',
      DOCUMENT_APPROVAL_6: 'अनुमोदित दस्तावेजों की सूची',
      DUE_DATE_2: 'पिछले कारण के बाद दोहराएं',
      ERROR_1: 'वितरण अधिसूचना विफलता (जैसे गलत ईमेल)',
      FOLLOW_UP_1: 'एक अनुवर्ती या अनुस्मारक ग्राहक को भेजा गया था',
      FOLLOW_UP_4: 'एक अनुवर्ती प्राप्त किया गया था',
      IMPORT_DATA_1: 'आयात के दौरान एक नई वस्तु बनाई गई है',
      IMPORT_DATA_4: 'आयात के दौरान एक नया उपयोगकर्ता बनाया गया है',
      IMPORT_DATA_5:
        'एक उपयोगकर्ता पहले से मौजूद एक से मेल खा रहा था, लेकिन पिछले आयात से नहीं आ रहा था',
      IMPORT_DATA_6: 'बाहरी स्रोत से डेटा के आयात के दौरान एक त्रुटि हुई',
      NEW_DOCUMENT_1: 'नया स्वीकृत दस्तावेज मेरे देश में उपलब्ध है',
      NEW_DOCUMENT_2: 'नया दस्तावेज़ स्थानीय अनुमोदन के लिए उपलब्ध है',
      NEW_DOCUMENT_3: 'नया स्थानीय दस्तावेज यूरोपीय / वैश्विक अनुमोदन के लिए उपलब्ध है',
      NEW_DOCUMENT_4: 'नया वैश्विक दस्तावेज उपलब्ध है',
      NEW_DOCUMENT_5: 'नया यूरोपीय दस्तावेज उपलब्ध है',
      NEW_DOCUMENT_6: 'वैश्विक अनुमोदन के लिए नया यूरोपीय दस्तावेज उपलब्ध है',
      SCHEDULER_TASK_1: 'किसी कार्य के निष्पादन के दौरान एक त्रुटि हुई',
      USER_REPORTS_1: 'सक्रिय उपयोगकर्ताओं की सूची',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'जब उपयोगकर्ता भूमिका अनुरोध को अनुमोदित/अस्वीकार करने की आवश्यकता होती है, तो आपको सूचित करते हुए सूचनाएं प्राप्त होती हैं',
        AUTHORIZATION_REQUEST_2:
          'जब नया उपयोगकर्ता बनाया गया था या जब आपकी टीम में किसी उपयोगकर्ता द्वारा एक भूमिका का अनुरोध किया गया था, तो सूचनाएं प्राप्त करें',
        AUTHORIZATION_REQUEST_4:
          'जब एक नया विषय और/या श्रेणी का सुझाव दिया गया तो सूचनाएं प्राप्त करें',
        FOLLOW_UP_4:
          'मुझे सौंपे गए पूछताछ के लिए सूचनाएं प्राप्त करें, जिसमें पूछताछकर्ता से अनुवर्ती प्रतिक्रिया प्राप्त हुई हो',
        NEW_INQUIRY_1:
          'अपने देश में प्राप्त नई पूछताछ, किसी पूछताछकर्ता/उपयोगकर्ता द्वारा डाली गई या किसी अन्य टीम से अग्रेषित की गई सूचनाओं को प्राप्त करें',
        ANSWER_2:
          'उन पूछताछों के लिए सूचनाएं प्राप्त करें जिनके उत्तर में कहा गया था कि मैंने सहयोगी के रूप में काम किया है, लेकिन मुझे सीधे पूछताछ के लिए नियुक्त नहीं किया गया है',
        DUE_DATE_1:
          'इस विकल्प को चुनने पर नीचे दी गई सभी अधिसूचनाएँ सक्रिय हो जाएँगी। अधिसूचनाओं की संख्या कम करने के लिए, उन अधिसूचनाओं को अचयनित करें जिनकी आवश्यकता नहीं है।',
        INQUIRY_APPROVAL_5:
          'आपके प्रस्तावित उत्तर को अस्वीकृत कर दिया गया था या बातचीत की स्थिति अनुमोदन के लिए प्रस्तुत से प्रगति में अपडेट कर दी गई थी, क्योंकि बातचीत के माध्यम से विभाजन हुआ था, नई पूछताछ के रूप में विलय के परिणामस्वरूप विभाजन हुआ था, या बाद की पूछताछ की स्वीकृति की आवश्यकता के संदर्भ में टीम सेटिंग में कोई भी बदलाव हुआ था।',
        INQUIRY_APPROVAL_4: 'आपके प्रस्तावित उत्तर को स्वीकृत कर दिया गया।',
        METADATA_MANAGEMENT_2:
          'निर्माण के कारण से स्वतंत्र नव निर्मित उत्पाद के लिए अधिसूचना प्राप्त करें।',
        METADATA_MANAGEMENT_3:
          'नव निर्मित श्रेणी के लिए अधिसूचना प्राप्त करें, निर्माण के कारण से स्वतंत्र।',
        METADATA_MANAGEMENT_4:
          'सृजन के कारण से स्वतंत्र नव निर्मित विषय के लिए अधिसूचना प्राप्त करें।',
        FORWARD_1:
          'इंटरैक्शन डेटा को संपादकों और असाइन करने वाले उपयोगकर्ता और/या विभाग के लिए विफल होने के मामले में असाइनमेंट के लिए अधिसूचना को अग्रेषित किया जाता है।',
      },
      FREQUENCIES: {
        INSTANTLY: 'हाथों हाथ',
        DAILY: 'रोज',
        WEEKLY: 'साप्ताहिक',
        QUARTERLY: 'त्रैमासिक',
        MONTHLY: 'महीने के',
        SECOND_DAY: 'हर दूसरे दिन',
        THIRD_DAY: 'हर तीसरे दिन',
        ONE_WEEK: 'एक हफ्ता',
        ONE_MONTH: 'एक महीना',
        TWO_MONTHS: 'दो महीने',
        THREE_MONTHS: 'तीन महीने',
        SIX_MONTHS: 'छह महीने',
        NINE_MONTHS: 'नौ महीने',
        EVERY_TWO_WEEKS: 'हर दो हफ्ते',
        EVERY_THREE_WEEKS: 'हर तीन सप्ताह',
        EVERY_FOUR_WEEKS: 'हर चार सप्ताह',
      },
      OPTIONS: {
        ALMOST_DUE: 'लगभग देय',
        DUE_NOW: 'अभी बकाया है',
        DUE_SOON: 'जल्द ही देय',
        EARLY_ON: 'शुरुआत से ही',
        TEXT_GUIDES: {
          DUE_NOW: 'पूछताछ की तिथि आने पर सूचना प्राप्त करें',
          EARLY_ON:
            'नियत तिथि से 24 घंटे पहले पूछताछ के लिए सूचनाएं प्राप्त करें: सामान्य 4 दिन - उच्च 2 दिन - महत्वपूर्ण',
          DUE_SOON:
            'जल्द ही होने वाली पूछताछ के लिए सूचनाएं प्राप्त करें: सामान्य 48 घंटे - उच्च 12 घंटे - महत्वपूर्ण नियत तारीख से 6 घंटे पहले',
          ALMOST_DUE:
            'लगभग नियत तिथि से पहले पूछताछ के लिए सूचनाएं प्राप्त करें: सामान्य 24 घंटे - उच्च 6 घंटे - महत्वपूर्ण नियत तिथि से 3 घंटे पहले',
        },
      },
      AUTHORIZATION_REQUEST_4: 'मेरी टीम में एक श्रेणी/ विषय का अनुरोध किया गया था',
      AUTHORIZATION_REQUEST_2: 'उपयोगकर्ता अनुरोध',
      IMPORT_DATA_2: 'आयात के दौरान एक नया पूछताछकर्ता बनाया गया है',
      IMPORT_DATA_3:
        'एक पूछताछकर्ता पहले से मौजूद पूछताछकर्ता से मेल खा रहा था, लेकिन पिछले आयात से नहीं आ रहा था',
      INTERNAL_1: 'मेरी निर्दिष्ट जांच में एक नोट जोड़ा गया',
      ANSWER_1: 'मेरे द्वारा उत्तर दिए गए संवादों में उत्तर की प्रति प्राप्त करें',
      ANSWER_6: 'मेरे द्वारा अनुमोदित बातचीत में उत्तर की प्रति प्राप्त करें',
      INQUIRY_APPROVAL_5: 'आपका प्रस्तावित उत्तर अस्वीकृत कर दिया गया',
      INQUIRY_APPROVAL_4: 'आपका प्रस्तावित उत्तर स्वीकृत हो गया',
      METADATA_MANAGEMENT_1: 'CRM आयात के दौरान भूगोल और संरेखण',
      METADATA_MANAGEMENT_2: 'एक नया उत्पाद बनाया गया है',
      METADATA_MANAGEMENT_3: 'एक नई श्रेणी बनाई गई है',
      METADATA_MANAGEMENT_4: 'एक नया विषय बनाया गया है',
      ANSWER_2: 'बातचीत जिसमें मैं शामिल था, का जवाब दिया गया था',
      ASSIGNMENT_1: 'बातचीत मुझे सौंपा गया था',
      ASSIGNMENT_5: 'बातचीत मेरे डिप्टी को सौंपी गई थी',
      CLOSE_1: 'बातचीत बंद थी',
      DUE_DATE_1: 'बातचीत जो मुझे सौंपी गई है वह नियत तारीख के पास है, मुझे सूचित करें',
      FOLLOW_UP_7: 'बातचीत करने के लिए बातचीत खो गई थी',
      FORWARD_1: 'आगे की बातचीत',
      INQUIRY_APPROVAL_1: 'बातचीत आपकी मंजूरी की प्रतीक्षा कर रही है',
      NEW_INQUIRY_1: 'मेरे देश में नई बातचीत प्राप्त हुई',
      SPAM_1: 'स्पैम में प्राप्त नई बातचीत',
    },
  },
  NEW_TEAM: 'नई टीम',
  SmPc: 'एसएमपीसी',
  USERS: 'उपयोगकर्ताओं',
  PAGINATOR_NEXT_PAGE_LABEL: 'अगला पृष्ठ',
  PAGINATOR_PREV_PAGE_LABEL: 'पिछला पृष्ठ',
  PAGINATOR_FIRST_PAGE_LABEL: 'पहला पन्ना',
  PAGINATOR_LAST_PAGE_LABEL: 'अंतिम पृष्ठ',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'आइटम प्रति पेज',
  Date: 'दिनांक',
  Action: 'गतिविधि',
  'Action by user': 'उपयोगकर्ता द्वारा कार्रवाई',
  'Changed user': 'परिवर्तित उपयोगकर्ता',
  'Original value': 'असली कीमत',
  'New value': 'नया मूल्य',
  REGION: 'क्षेत्र',
  TEAM_REGION_LABEL: 'क्षेत्र - कृपया पहले कंपनी का चयन करें',
  TEAM_THERAPEUTIC_AREA_LABEL: 'चिकित्सीय क्षेत्र - कृपया पहले कंपनी का चयन करें',
  TEAM_PRODUCT_LABEL: 'उत्पाद - कृपया पहले चिकित्सीय क्षेत्र का चयन करें',
  TEAM_COUNTRIES_LABEL: 'देश - कृपया पहले क्षेत्र का चयन करें',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'प्रत्येक देश के लिए उत्पादों को परिभाषित करें',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'कृपया के लिए कम से कम एक उत्पाद का चयन करें',
  VIEW_CHANGES: 'परिवर्तन देखें',
  Sales_Representative: 'बिक्री प्रतिनिधि',
  Other_Commercial: 'अन्य वाणिज्यिक',
  REGIONS: 'क्षेत्रों',
  MAIN_CLIENT: 'मुख्य ग्राहक',
  LICENSE_PARTNER: 'लाइसेंस भागीदार',
  TEAM_SET_UP: 'टीम सेट अप',
  LIST_TEAMS: 'सूची टीम',
  COMPANIES: 'कंपनियों',
  DOMAINS: 'डोमेन',
  TOKEN_REFRESH_NOTICE:
    'आपने अपने ENQMED खाता पंजीकरण के लिए एक नया पुष्टिकरण ईमेल का अनुरोध किया है।',
  TOKEN_REFRESH_SUCCESS: 'आपके ईमेल पर एक नया पुष्टिकरण लिंक भेजा गया है।',
  TOKEN_REFRESH_ERROR:
    'एक त्रुटि हुई। यदि आपको और सहायता की आवश्यकता है तो कृपया समर्थन से संपर्क करें।',
  PLEASE_ENTER_MAX: 'कृपया अधिकतम दर्ज करें',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'कृपया नीचे दिए गए परिवर्तनों की समीक्षा करें, यदि कोई हो, और पुष्टि करें कि क्या कंपनी को बदला जाना चाहिए।',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'कृपया सावधानी के साथ आगे बढ़ें क्योंकि परिवर्तन टीमों को प्रभावित करेंगे।',
  THERE_ARE_NO_CHANGES: 'कोई बदलाव नहीं हैं।',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा कंपनियों की जांच करें क्योंकि हमारे पास पहले से ही समान मूल्यों के साथ कंपनी है।',
  SIMPLE_TEXT:
    'कृपया केवल पत्र (ए-जेड, ए-जेड), संख्या (0-9) और विशेष प्रतीक जैसे (डैश, पीरियड, स्पेस और बृहदान्त्र) दर्ज करें।',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'कृपया क्षेत्र के लिए कम से कम एक देश का चयन करें',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'कृपया चिकित्सीय क्षेत्र के लिए कम से कम एक उत्पाद का चयन करें',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'आपने उत्पाद/एस का चयन किया है जो एक से अधिक चिकित्सीय क्षेत्र से संबंधित है, कृपया सभी आवश्यक चिकित्सीय क्षेत्रों का चयन करें।',
  HTML_CHANGES: 'HTML बदल जाता है',
  SIDE_BY_SIDE_VIEW: 'साइड -साइड व्यू',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा डोमेन की जाँच करें क्योंकि हमारे पास पहले से ही समान मानों के साथ डोमेन है।',
  OUT_CHANNEL_ERROR_MSG: 'आउटगोइंग चैनल लेटर Enquirerâ € ™ का डाक पता',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'आउटगोइंग चैनल को पत्र के रूप में चुना गया। कृपया Enquirerâ € ™ के डाक पता Enquirer क्षेत्र के भीतर दर्ज करें',
  LABEL_FOR: 'के लिए लेबल',
  male: 'पुरुष',
  female: 'मादा',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'आपके पास इस भूमिका को संग्रह करने के अधिकार नहीं हैं। टीम के लिए केवल <b> mims </b> उनकी टीम के सदस्यों के लिए भूमिकाएँ संग्रह कर सकते हैं।',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION: 'अनुरोध अनुमोदन/अस्वीकृति पर उपयोगकर्ता को ईमेल भेजें',
  APPROVE_REQUEST_QUESTION: 'क्या आप अनुरोध को मंजूरी देना चाहते हैं?',
  NEW_USER_REQUEST: 'नया उपयोगकर्ता अनुरोध',
  OPEN_REQUESTS: 'खुले अनुरोध',
  APPROVED_REQUESTS: 'अनुमोदित अनुरोध',
  REJECTED_REQUESTS: 'अनुरोधों को अस्वीकार कर दिया',
  USER_REQUESTS: 'उपयोगकर्ता अनुरोध',
  REQUEST_FOR: 'के लिए अनुरोध',
  CATEGORY_TOPIC_REQUESTS: 'श्रेणी/विषय अनुरोध',
  WITH_THE_FOLLOWING_PAIRS: 'निम्नलिखित जोड़े के साथ:',
  MY_TEAM_OPEN_REQUESTS: 'खुले अनुरोध',
  MY_TEAM_APPROVED_REQUESTS: 'अनुमोदित अनुरोध',
  MY_TEAM_REJECTED_REQUESTS: 'अनुरोधों को अस्वीकार कर दिया',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'EnqMed के भीतर दस्तावेज़ अनुमोदन',
  DOCUMENTS_APPROVAL: 'दस्तावेज़ स्वीकृति',
  DOCUMENTS_APPROVAL_TITLE: 'चयनित टीम के लिए दस्तावेज़ अनुमोदन प्रक्रिया के लिए सेटिंग्स',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'आउटगोइंग चैनल को फैक्स के रूप में चुना गया। कृपया Enquirer का फैक्स नंबर Enquirer क्षेत्र के भीतर दर्ज करें',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'आउटगोइंग चैनल को फोन के रूप में चुना गया। कृपया Enquirer का फोन नंबर Enquirer क्षेत्र के भीतर दर्ज करें',
  OUT_CHANNEL_FAX_ERROR_MSG: 'आउटगोइंग चैनल फैक्स एनक्वायरर का मूल्य',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'आउटगोइंग चैनल फोन एनक्वायरर का मूल्य',
  COPYRIGHT_PART_ONE: 'कॉपीराइट',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. सभी अधिकार सुरक्षित।',
  REVIEWED_DATE: 'समीक्षा तिथि',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'आउटगोइंग चैनल को ईमेल के रूप में चुना गया। कृपया Enquirer का ईमेल Enquirer क्षेत्र के भीतर दर्ज करें',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'आउटगोइंग चैनल ईमेल एनक्वायरर का मूल्य',
  ENTER_USER_COMMENT_OPTIONAL: 'उपयोगकर्ता के लिए टिप्पणी दर्ज करें (वैकल्पिक)',
  SELECT_ANOTHER_CATEGORY: 'एक और श्रेणी का चयन करें',
  SELECT_ANOTHER_PRODUCT: 'एक और उत्पाद का चयन करें',
  SUGGESTED_TOPIC: 'सुझाया गया विषय',
  EDIT_USER_REQUEST: 'उपयोगकर्ता अनुरोध संपादित करें',
  NEW_TOPIC_REQUEST: 'नए विषय का सुझाव दिया',
  EDIT_TOPIC_REQUEST: 'सुझाए गए विषय को संपादित करें',
  NEW_CATEGORY_REQUEST: 'नई श्रेणी का सुझाव दिया',
  EDIT_CATEGORY_REQUEST: 'सुझाए गए श्रेणी को संपादित करें',
  REVIEW_COMMENT: 'समीक्षा टिप्पणी',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'दस्तावेज़ अनुमोदन वर्कफ़्लोज़',
    CREATE_NEW: 'नया वर्कफ़्लो बनाएं',
    CUSTOMIZE: 'वर्कफ़्लो को अनुकूलित करें',
    NAME: 'वर्कफ़्लो नाम',
    ERROR_MSG_DUPLICATE_NAME: 'इस नाम के साथ वर्कफ़्लो पहले से मौजूद है।',
    SAVE: 'वर्कफ़्लो बचाएं',
    DELETE: 'वर्कफ़्लो हटाएं',
    STEP: 'कदम',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'दस्तावेज़ लेखक की भूमिका वाले सभी उपयोगकर्ता।',
    APPROVAL_DEADLINE: 'अनुमोदन समय सीमा',
    SELECT_APPROVAL_TYPE: 'अनुमोदन प्रकार का चयन करें',
    SELECT_APPROVAL_TYPE_FIRST: 'पहले अनुमोदन प्रकार का चयन करें',
    SELECT_REVIEWERS: 'समीक्षकों का चयन करें',
    ACTION_ON_APPROVE_WITH_CHANGES: 'परिवर्तनों के साथ अनुमोदन पर कार्रवाई',
    ACTION_ON_REJECT: 'अस्वीकार पर कार्रवाई',
    FILL_ALL_REQUIRED_FIELDS: 'नए कदम जोड़ने के लिए कृपया सभी आवश्यक फ़ील्ड भरें।',
    ALL_APPROVAL_TYPES_USED:
      'आप अधिक अनुमोदन उप चरणों को नहीं जोड़ सकते क्योंकि आपने वर्तमान चरण के लिए उपलब्ध सभी प्रकार का उपयोग किया था।',
    CONFIRMATION_ROW_1:
      'आप टीम के लिए दस्तावेज़ अनुमोदन वर्कफ़्लो को हटाने की कोशिश कर रहे हैं {{ NAME }}.',
    CONFIRMATION_ROW_2: 'क्या आप वास्तव में अनुमोदन वर्कफ़्लो को हटाना चाहते हैं?',
    CONFIRMATION_TITLE: 'दस्तावेज़ अनुमोदन वर्कफ़्लो विलोपन',
    TEAM_VIEW_SELECT_EXISTING:
      'नीचे दी गई सूची से एक मौजूदा दस्तावेज़ अनुमोदन वर्कफ़्लो का चयन करें या',
    DOC_VIEW_SELECT_EXISTING: 'एक मौजूदा दस्तावेज़ अनुमोदन वर्कफ़्लो का चयन करें',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'केवल एकल अनुमोदन कदम को अंतिम के रूप में सूचीबद्ध किया जा सकता है।',
    APPROVAL_TYPE_TOOLTIP:
      'इस टीम के लिए दस्तावेज़ अनुमोदन भूमिका वाले कोई उपयोगकर्ता नहीं हैं। कृपया पहले अनुमोदन भूमिकाएं जोड़ें।',
    INFO_DIALOG_TITLE: 'इस टीम के लिए कोई दस्तावेज़ अनुमोदन वर्कफ़्लो सेट नहीं है।',
    INFO_DIALOG_MSG:
      'टीम के लिए दस्तावेज़ अनुमोदन वर्कफ़्लो बनाने के लिए कृपया अपने स्थानीय एमआईएम से संपर्क करें।',
    STEP_LIMIT_REACHED: 'आप {{ MAX_STEPS }} अनुमोदन चरणों की सीमा तक पहुंच गए हैं.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'दस्तावेज़ प्रकार जिन्हें अनुमोदन की आवश्यकता नहीं है',
  PLEASE_SELECT_TYPE: 'कृपया टाइप का चयन करें',
  ABORT_FILE_UPLOAD: 'एबोर्ट फ़ाइल अपलोड',
  USERS_REPORT: 'उपयोगकर्ता रिपोर्ट',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'इस विंडो को बंद करके आप पहले से भरी जानकारी को ढीला कर देंगे।',
  DO_YOU_WANT_TO_PROCEED: 'क्या आपकी आगे बढ़ने की इच्छा है?',
  USERS_REPORT_DESCRIPTION:
    'सभी अतिरिक्त टीमों के उपयोगकर्ताओं की सूची जहां दाइची संकियो यूरोप ऑन्कोलॉजी और कार्डियोवास्कुलर के लिए एमआईएम की एमआईएम की भूमिका है।',
  TEMPLATE_CONFIRM_MSG: 'एक बार टेम्पलेट हटा दिया जाता है, तो इसे बहाल नहीं किया जा सकता है!',
  EVENT_CONFIRM_MSG: 'एक बार घटना संग्रहीत होने के बाद, इसे बहाल नहीं किया जा सकता है!',
  CREATE_DRAFT: 'ड्राफ्ट बनाएं',
  CREATE_USER: 'उपयोगकर्ता बनाइये',
  ADD_ROLE: 'भूमिका जोड़ें',
  DOMAIN_ADDRESS_INVALID_FOR: 'के लिए डोमेन पता अमान्य',
  AVAILABLE_ADDRESSES: 'उपलब्ध पते',
  IN_TEAM: 'टीम में',
  TO_THE_SELECTED_USER: 'चयनित उपयोगकर्ता को',
  USER_INFORMATION: 'यूजर जानकारी',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'आपके द्वारा उपयोग किए जा रहे ईमेल पते को अपडेट करने की आवश्यकता है क्योंकि डोमेन पता टीम/एस के अनुरूप नहीं है, आपकी प्रोफ़ाइल के साथ जुड़ा हुआ है।',
  MESSAGE: 'संदेश',
  IN_APPROVAL: 'अनुमोदन में',
  IN_REVIEW: 'रिव्यू में',
  DOCUMENT_APPROVAL: 'दस्तावेज़ अनुमोदन',
  DOCUMENT_TITLE: 'दस्तावेज़ का शीर्षक',
  SEARCH_DOCUMENTS: 'खोज दस्तावेज',
  APPROVAL_INBOX: 'अनुमोदन इनबॉक्स',
  FILTER_DOCUMENTS_BY: 'द्वारा फ़िल्टर दस्तावेज',
  submit_to_approval: 'अनुमोदन के लिए प्रस्तुत करें',
  create_document_metadata: 'दस्तावेज़ मेटाडेटा बनाएं',
  archive: 'पुरालेख',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'मैं स्वीकार करता हूं कि स्थानीय आवश्यकताओं के अनुसार सभी चिकित्सा उत्पादों के सभी प्रतिकूल दवा प्रतिक्रियाओं (एडीआर) की रिपोर्ट करना मेरा दायित्व है। Enqmed का उद्देश्य प्रतिकूल दवा प्रतिक्रियाओं के बारे में रिपोर्टों को पकड़ने के लिए नहीं है - ADRs को स्थानीय आवश्यकताओं के अनुसार सीधे फार्माकोविगिलेंस को सूचित किया जाना चाहिए',
  VERSION_DIALOG: {
    TITLE: 'नया संस्करण उपलब्ध है',
    MESSAGE:
      'सॉफ़्टवेयर का नया संस्करण उपलब्ध है!\n आपको नया संस्करण प्राप्त करने की आवश्यकता है, इसमें सुधार से लाभ उठाने के लिए और पुराने संस्करण का उपयोग करने से होने वाली समस्याओं से बचने के लिए, आपको इसे प्राप्त करने की आवश्यकता है।',
    SAVE_BUTTON: 'नया संस्करण प्राप्त करें',
  },
  ACTIVE_INACTIVE: 'सक्रिय निष्क्रिय',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा श्रेणियों की जाँच करें क्योंकि हमारे पास पहले से ही समान मूल्यों वाली श्रेणी है।',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'गलती! विफल डिलीवरी',
  VIA: 'के जरिए',
  INSERTED_MANUALLY: 'मैन्युअल रूप से डाला गया',
  AS_A: 'के तौर पर',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा उत्पादों की जांच करें क्योंकि हमारे पास पहले से ही समान मूल्यों वाले उत्पाद हैं।',
  NOT_PRODUCT_SPECIFIC: 'उत्पाद विशिष्ट नहीं',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा विषयों की जाँच करें क्योंकि हमारे पास पहले से ही समान मान वाले विषय हैं।',
  PLEASE_ENTER_MAX_CHARACTERS: 'कृपया अधिकतम {{ MAX_CHARS }} अक्षर दर्ज करें।',
  THANK_YOU_MESSAGE: 'धन्यवाद संदेश',
  AS: 'जैसा',
  THERAPEUTIC_AREA_PARENT: 'मूल क्षेत्र',
  MAIN_THERAPEUTIC_AREA: 'मुख्य चिकित्सीय क्षेत्र',
  CHOOSE_AN_IMAGE: 'एक छवि चुनें',
  ALLOWED_FORMATS: 'केवल {{ FORMATS }} प्रारूपों की अनुमति है',
  REMOVE_IMAGE: 'छवि हटाएँ',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा चिकित्सीय क्षेत्रों की जाँच करें क्योंकि हमारे पास पहले से ही समान मान वाले क्षेत्र हैं।',
  PLAIN_TEXT_VIEW: 'सादा पाठ दृश्य',
  RICH_TEXT_VIEW: 'रिच टेक्स्ट व्यू',
  EDIT_ITEM: '{{ ITEM }} संपादित करें',
  CREATE_NEW_ITEM: 'नया {{ ITEM }} बनाएं',
  ADD_NEW_ITEM: 'नया जोड़ें {{ ITEM }}',
  ADD_ITEM: '{{ ITEM }} जोड़ें',
  AND_HENCE_STATUS_CHANGED_FROM: 'और इसलिए स्थिति बदल गई',
  FOLLOW_UP_WITH_ENQUIRER: 'पूछताछकर्ता के साथ अनुवर्ती कार्रवाई',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'परिवर्तनों को सहेजने के लिए निम्नलिखित सम्मिलित करना होगा:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'बंद बातचीत के लिए अनुवर्ती कार्रवाई संभव नहीं है।',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'समीक्षा स्थिति में सहभागिता. कृपया इस मेनू को सक्षम करने के लिए समीक्षा सहेजें।',
  QUESTION_TEXT_MIN_SIX_CHARS: 'प्रश्न पाठ (न्यूनतम 6 अक्षर)',
  PLEASE_PROVIDE_AN_ANSWER_: 'कृपया उत्तर दें (न्यूनतम 6 अक्षर या एक अनुलग्नक)।',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "कृपया ध्यान दें कि उपयोगकर्ता को 'पासवर्ड भूल गए' से पासवर्ड पुनर्प्राप्त करना होगा।",
  ENABLE_USER_LOGIN_MESSAGE: 'इससे उपयोगकर्ता लॉगिन सक्षम हो जाएगा.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE: 'इससे उपयोगकर्ता लॉगिन और सूचनाएं अक्षम हो जाएंगी.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'कृपया अपनी उपयोगकर्ता प्रोफ़ाइल से अपनी अधिसूचना प्राथमिकताएँ निर्धारित करें!',
  SET_PASSWORD: 'कृपया अपना पासवर्ड सेट करें',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'आपके खाते के पुनः सक्रिय होने के बाद, आपका पासवर्ड मिटा दिया गया था।',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'विशेषज्ञता निष्क्रियकरण की पुष्टि करें!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'यह इसे सक्रिय विशेषज्ञता वाली सूची से हटा देगा.',
  GO_TO_ROLES: 'भूमिकाओं पर जाएँ',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'उपयोगकर्ता संपर्क जानकारी में आपके परिवर्तनों के कारण लॉगिन ईमेल का परिवर्तन {{ OLD_USERNAME }} से {{ NEW_USERNAME }} हो गया।',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'कृपया भूमिकाएँ और अधिसूचनाएँ निर्दिष्ट करें।',
  DO_YOU_WANT_TO_CONTINUE: 'क्या आप जारी रखना चाहते हैं?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'नए इंटरैक्शन में उत्तर कॉपी करें',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'नई पूछताछ आयात की गई है। सभी पूछताछों का विवरण और उत्तर देना सुनिश्चित करें।',
  LEAVE_WITHOUT_SAVING: 'बिना सहेजे छोड़ें',
  PENDING_CHANGES_MESSAGE: 'लंबित परिवर्तन {{ CHANGES }} हैं?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'क्या आप वाकई चयनित टेक्स्ट को नए इंटरैक्शन में विभाजित करना चाहते हैं?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'यह चयनित टेक्स्ट को इंटरैक्शन से हटा देगा और उससे एक नया टेक्स्ट बना देगा।',
  DOWNLOAD_ERROR: 'डाउनलोड त्रुटि!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'आप 1000 से अधिक पूछताछ डाउनलोड करने का प्रयास कर रहे हैं। अधिकतम समर्थित संख्या 1000 है। कृपया अपना चयन परिष्कृत करें।',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'आप प्रश्न का लक्षण वर्णन बदलने का प्रयास कर रहे हैं!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'कृपया ध्यान दें कि प्रश्न का चरित्र-चित्रण बदलने से पहले दिए गए उत्तर में कोई बदलाव नहीं होगा या नया उत्तर भेजने की अनुमति नहीं होगी!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'आप एक नया उत्तर भेजने के लिए एक बंद बातचीत को फिर से खोलने वाले हैं।',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'क्या आप वाकई इस फॉलोअप को एक प्रश्न में बदलना चाहते हैं?',
  ARE_YOU_SURE: 'क्या आपको यकीन है?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} को निष्क्रिय करने की पुष्टि करें',
  USER_REACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} के पुनः सक्रियण की पुष्टि करें',
  USER_REACTIVATED_MESSAGE: 'उपयोगकर्ता {{ USER_INFO }} सफलतापूर्वक पुनः सक्रिय हो गया!',
  DELETE_NOT_POSSIBLE: 'हटाना संभव नहीं!',
  SEARCH_NO_LONGER_EXIST: 'यह खोज अब मौजूद नहीं है क्योंकि इसे प्रेषक द्वारा हटा दिया गया था।',
  LOGIN_CREDENTIALS_CHANGED: 'लॉगिन क्रेडेंशियल बदल गए!',
  EDIT_TEXT: 'संमपादित पाठ',
  FOLLOW_UP_ATTEMPTS: 'अनुवर्ती प्रयास',
  FIRST_FOLLOW_UP_INFO_TEXT: 'व्यक्तिगत बातचीत के अंतर्गत पहला अनुवर्ती भेजा गया',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'यदि प्रारंभिक अनुरोध के बाद निम्नलिखित में से कोई भी निष्क्रिय हो जाता है तो प्रक्रिया अगले चरण में स्थानांतरित हो जाएगी',
  SECOND_ATTEMPT: 'दूसरा प्रयास (पहला अनुस्मारक)',
  THIRD_ATTEMPT: 'तीसरा प्रयास (पहला अनुस्मारक)',
  FINAL_ATTEMPT: 'फॉलो-अप में खो गया (बंद करें)',
  REMINDER_FREQUENCY: 'अनुस्मारक आवृत्ति',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'पूछताछकर्ता से संपर्क करने के लिए उपयोग की जाने वाली भाषा में स्वचालित अनुवाद लागू करें',
  ON_OFF_SWITCH: 'बंद',
  DAY: 'दिन',
  DAYS: 'दिन',
  MONTH: 'महीना',
  MONTHS: 'महीने',
  RESET_TEMPLATES_TO_DEFAULTS: 'टेम्प्लेट को डिफ़ॉल्ट पर रीसेट करें',
  SAVE_FREQUENCY: 'आवृत्ति सहेजें',
  SELECT_FREQUENCY: 'आवृत्ति चुनें',
  TEMPLATE_MODIFICATIONS_HINT:
    'पाठ को मैन्युअल रूप से संशोधित किया गया है और अब इसे स्वचालित रूप से नहीं बदला जाएगा। संशोधनों को त्यागने और स्वचालित अपडेट को पुनः सक्षम करने के लिए यहां क्लिक करें।',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'गलत ई - मेल पता!',
  AUDIT_TRAIL_FOR: '{{ ITEM }} के लिए ऑडिट ट्रेल',
  AUDIT_TRAIL_FOR_ALL: 'सभी {{ ITEM }} के लिए ऑडिट ट्रेल',
  HISTORY_CHANGES_FOR: 'इतिहास बदलता है',
  HOLIDAY_GDPR_DISCLAIMER: 'अवकाश जीडीपीआर अस्वीकरण',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'अवकाश जीडीपीआर अस्वीकरण प्रबंधित करें',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'निम्नलिखित टीम/टीमों के लिए अवकाश जीडीपीआर टेम्पलेट अनुपलब्ध है',
  BULK_CREATE: 'थोक निर्माण',
  LAST_SUCCESS: 'अंतिम सफलता',
  LAST_FAILURE: 'अंतिम विफलता',
  FAILURE: 'असफलता',
  TASK: 'काम',
  Weeks: 'हफ्तों',
  REPEAT_EVERY: 'प्रत्येक पर दोहराएँ',
  DEACTIVATE_ITEM: '{{ ITEM }} निष्क्रिय करें',
  RESET_FAILURE_COUNT: 'विफलता गणना रीसेट करें',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा कार्यों की जांच करें क्योंकि हमारे पास पहले से ही समान मानों के साथ कार्य हैं।',
  ACTIVATE_ITEM: '{{ ITEM }} सक्रिय करें',
  PLEASE_ENTER_MIN_NUMBER: 'कृपया {{ MIN_NUMBER }} से बड़ी संख्या दर्ज करें।',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'मर्ज के लिए चयनित लक्ष्य इंटरैक्शन पहले ही बंद हो चुका है। यह पूछताछ एक नई बातचीत है और इसे विलय करने की कोई आवश्यकता नहीं है।',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'मौजूदा पूछताछ में एक नया प्रश्न जोड़ा जाएगा, सभी प्रश्नों का उत्तर दिया जाना चाहिए। कृपया मर्ज के लिए प्रश्न चुनें.',
    VALIDATION_MSG:
      'मर्ज के साथ आगे बढ़ने के लिए कृपया मर्ज किए गए इंटरैक्शन से कम से कम एक प्रश्न चुनें।',
  },
  MERGED_INTERACTION: 'मर्ज की गई बातचीत',
  TARGET_INTERACTION: 'लक्ष्य सहभागिता',
  MERGE_SUGGESTION: 'सुझाव मर्ज करें',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'कृपया नीचे दिए गए मर्ज सुझाव की समीक्षा करें',
  INQUIRERS_COMPARISON_TITLE:
    'कृपया मर्ज किए गए इंटरैक्शन और लक्ष्य इंटरैक्शन के लिए अलग-अलग पूछताछकर्ताओं पर ध्यान दें',
  INQUIRERS_COMPARISON_QUESTION:
    'कृपया पुष्टि करें कि क्या आप इस अंतर के बावजूद आगे बढ़ना चाहेंगे।',
  TERRITORY: 'इलाका',
  DOMAIN: 'कार्यक्षेत्र',
  IN: 'में',
  TEAM_GROUP_FILTER: {
    TITLE: 'अतिरिक्त टीमों को देखने के लिए अनुमतियाँ परिभाषित करें',
    SAVE_BTN_TOOLTIP: 'कृपया टीम के लिए सभी अनिवार्य फ़ील्ड दर्ज करें और पहले टीम को सहेजें।',
  },
  ADR_REF_ID: 'एडीआर संदर्भ आईडी',
  PQC_REF_ID: 'पीक्यूसी संदर्भ आईडी',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'यह अनुलग्नक एकाधिक इंटरैक्शन में मौजूद होता है',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'यदि आप सभी इंटरैक्शन से अनुलग्नक हटाना चाहते हैं तो कृपया चेक-बॉक्स विकल्प चुनें।',
  CONFIRM_DELETE_ATTACHMENT: 'कृपया पुष्टि करें कि क्या आप इस अनुलग्नक को हटाना चाहते हैं।',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'ऊपर उल्लिखित सभी इंटरैक्शन से हटाएँ',
  ATTACHMENT_DELETED: 'अनुलग्नक हटा दिया गया',
  ROLES: 'भूमिकाएँ',
  SET_TO_ALL_INQ_TOOLTIP: 'सभी प्रश्नों के लिए समान ADR, PQC और ऑफ-लेबल लक्षण वर्णन सेट करें।',
  TASKS_LIST: 'कार्य सूची',
  TASK_LOGS: 'कार्य लॉग',
  SELECTED: 'चयनित',
  LICENSE_COUNTER: 'लाइसेंस काउंटर',
  LICENSES_LIMIT: 'लाइसेंस संख्या सीमा',
  USERS_TO_EXCLUDE: 'उपयोगकर्ताओं को बहिष्कृत करें',
  ENTER_MAX_NUMBER_OF_LICENSES: 'अनुबंधित लाइसेंस शुल्क (उपयोगकर्ता भूमिकाएँ)।',
  SELECT_USER_ROLES_TO_COUNT: 'लाइसेंस शुल्क गणना के लिए लागू उपयोगकर्ता भूमिकाएँ चुनें।',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'उपयोगकर्ताओं को लाइसेंस शुल्क गणना से बाहर रखें।',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'प्राप्ति और बिक्री सूचनाएं',
  REFRESH_MIGRATION_ERRORS: 'माइग्रेशन त्रुटियों की सूची ताज़ा करें',
  MARKED_AS_RESOLVED_BY: 'द्वारा समाधान के रूप में चिह्नित किया गया',
  MARKED_AS_RESOLVED_ON: 'पर समाधान के रूप में चिह्नित किया गया',
  MARK_AS_RESOLVED: 'सही के रूप में चिन्हित करो',
  ERROR_ON: 'त्रुटि चालू',
  TEXT_WITH_SPECIAL_CHARS:
    'कृपया केवल अक्षर (ए-जेड, ए-जेड), संख्याएं (0-9), और विशेष प्रतीक जैसे डैश (-), अवधि (.), स्पेस, कोलन (:), अल्पविराम (,), कोष्ठक (()), आगे दर्ज करें। स्लैश (/), और प्लस (+)।',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'अनुमोदन के लिए प्रस्तुत स्थिति में सहभागिता। कृपया इस मेनू को सक्षम करने के लिए स्वीकृत/अस्वीकार करें।',
  REFRESH_TASK_LOGS: 'कार्य लॉग सूची ताज़ा करें',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'यदि आप परिवर्तनों के साथ आगे बढ़ना चाहते हैं तो कृपया पुष्टि करें!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED:
    'प्रतिनियुक्त भूमिकाओं के लिए मैन्युअल अभिलेखन की अनुमति नहीं है।',
  RUN_COUNTER: 'दौड़ की गणना करने वाला यंत्र',
  EXPORT_LICENSES: 'निर्यात लाइसेंस',
  LICENSE_COUNT_MESSAGE: 'प्रयुक्त लाइसेंसों की संख्या है:',
  QUESTIONS: 'प्रशन',
  CLARIFICATION: 'स्पष्टीकरण',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'मर्ज के साथ आगे बढ़ने के लिए कृपया लक्ष्य इंटरैक्शन में कम से कम एक प्रश्न चुनें।',
    DESCRIPTION:
      'लक्ष्य बातचीत में चयनित प्रश्न(प्रश्नों) के अंतर्गत स्पष्टीकरण प्रश्न जोड़ा जाएगा।',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'मौजूदा इंटरैक्शन के डुप्लिकेट के रूप में इंटरैक्शन को फ़्लैग करने के लिए इस विकल्प का चयन करें।',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'स्पष्टीकरण प्रश्न के रूप में पिछला विलय पाया गया। लक्ष्य इंटरैक्शन में पहले से चयनित उत्तर फ़ील्ड के अलावा सभी चयनित उत्तर फ़ील्ड उत्तर के लिए फिर से खोले जाएंगे।',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'एक अन्य प्रश्न पर उत्तर प्रदान करने के लिए बातचीत पुनः खोली गई। इस प्रश्न का उत्तर पहले ही उपलब्ध करा दिया गया था।',
  TIP: 'बख्शीश',
  MERGE_TIP_TEXT:
    'यदि आप इसके बजाय इंटरैक्शन {{ MERGED_INTO_ID }} को फिर से खोलना चाहते हैं, तो कृपया नहीं दबाएं और लक्ष्य इंटरैक्शन {{ MERGED_INTO_ID }} को इंगित करते हुए एक नई मर्ज प्रक्रिया शुरू करें।',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'आप लक्ष्य इंटरैक्शन को खोले बिना मर्ज ऑपरेशन का चयन कर रहे हैं।',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'आप एक अमान्य लक्ष्य इंटरेक्शन {{ TARGET_INTERACTION_ID }} में मर्ज ऑपरेशन का चयन कर रहे हैं।<br>इंटरेक्शन {{ TARGET_INTERACTION_ID }} स्थिति {{ STATUS }}{{ LAST_MERGE_INFO }} के साथ बंद कर दिया गया था।',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'इसके परिणामस्वरूप बंद बातचीत होगी और उत्तर देने में असमर्थता होगी।',
  INTO_INTERACTION: 'बातचीत में',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'कृपया मर्ज के लिए इंटरैक्शन का चयन करें.',
  PLEASE_SELECT_MERGE_REASON: 'कृपया मर्ज का कारण चुनें.',
  QUESTION_CANNOT_BE_DELETED:
    'इस प्रश्न को हटाया नहीं जा सकता क्योंकि इससे जुड़ा एक अनुत्तरित स्पष्टीकरण प्रश्न है।',
  MERGE_REASON: 'विलय का कारण',
  WITH_REOPEN: 'पुनः खोलने के साथ',
  MERGED_INTO_QUESTION_IDS: 'प्रश्न आईडी में विलय कर दिया गया',
  MERGED_INTO_QUESTIONS: 'प्रश्न(प्रश्नों) में विलीन हो गया',
  MERGED_PREVIOUSLY_FROM: 'पहले से विलय किया गया',
  FROM_INTERACTION_ID: 'इंटरेक्शन आईडी से',
  IN_INTERACTION_ID: 'इंटरेक्शन आईडी में',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'आप मर्ज सुझाव रद्द करने वाले हैं.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'मर्ज सुझाव दोबारा न दिखाएं?',
  PRODUCT_RESOLVED_VIA_AI: 'उत्पाद (एआई के माध्यम से हल किया गया)',
  REQUIRED_FIELDS_HINT: 'कृपया सभी आवश्यक जगर भरें',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'कम से कम एक संपर्क आवश्यक है!',
  DISCLAIMER_INFO: 'अस्वीकरण जानकारी',
  QUESTION_ID: 'प्रश्न आईडी',
  QUESTION_IDS: 'प्रश्न आईडी',
  SHOW_TEAMS: 'टीमें दिखाएँ',
  LAST_POLLING: 'अंतिम मतदान',
  HOST: 'मेज़बान',
  PROTOCOL: 'शिष्टाचार',
  FROM_EMAIL: 'ई - मेल से',
  REASON_FOR_REMOVAL: 'निकाले जाने का कारण',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'एक बंद इंटरैक्शन संपादन योग्य नहीं है.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'इंटरैक्शन में एक से अधिक प्रश्न हैं. खुली बातचीत से ही संपादन संभव।',
  BULK_EDIT: 'थोक संपादित',
  HOW_TO_ENABLE_BULK_EDIT:
    'बल्क अपडेट करने के लिए एक ही भाषा में कम से कम दो टेम्पलेट का चयन किया जाना चाहिए।',
  REASON_FOR_UPDATE: 'अपडेट का कारण',
  FOLLOW_UP_RESEND: 'अनुवर्ती पुनः भेजा गया',
  SENT_FOLLOW_UP_REMINDER: 'अनुवर्ती अनुस्मारक भेजा गया',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'बातचीत बंद कर दी गई और अनुवर्ती सूचना खो गई',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'कृपया न्यूनतम 15 अक्षरों की लंबाई वाला पाठ दर्ज करें।',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST: 'फ़ॉलो-अप अक्षम! कृपया पहले पूछताछकर्ता को शामिल करें।',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'फ़ॉलो-अप अनुरोध अक्षम! कृपया पहले वैध पूछताछकर्ता का ईमेल डालें।',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'चयनित आवृत्ति प्रकार की संख्या 1 और {{ MAX_ALLOWED_FREQUENCY }} के बीच होनी चाहिए।',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'कृपया आवृत्ति प्रकार का चयन करें.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'गिनती प्रारंभिक फ़ॉलो-अप अनुरोध की तिथि और समय से शुरू होती है। दिनों को सप्ताह में बदलने के लिए 7 से गुणा करें, जैसे 2 सप्ताह = 14 दिन, दिनों को महीनों में बदलने के लिए 30 से गुणा करें, जैसे 1 महीना = 30 दिन।',
  VALID_PHONE_FAX: 'कृपया वैध फ़ोन/फ़ैक्स नंबर दर्ज करें!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'अनुवादित टेम्प्लेट देखने और प्रबंधित करने के लिए भाषा चुनें',
  RETRANSLATE: 'अनुवाद कर देना',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'नीचे दिए गए टेम्पलेट केवल अंग्रेजी भाषा में हैं, अनुवाद नीचे प्रबंधित किए गए हैं',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'उपरोक्त सभी टेम्पलेट्स को डिफ़ॉल्ट पर रीसेट कर दिया जाएगा (मैन्युअल संशोधन हटा दिया जाएगा, आवृत्ति 14 दिनों पर रीसेट कर दी जाएगी, और अनुवाद चेकबॉक्स अचयनित कर दिया जाएगा), सभी भाषाओं में पुनः अनुवादित किया जाएगा और is_manually_modified को साफ़ कर दिया जाएगा।',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'टेम्पलेट पाठ को संपादित करें और उन सभी अनुवादों का पुनः अनुवाद करें जिन्हें मैन्युअल रूप से संशोधित नहीं किया गया है।',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'मैन्युअल रूप से संशोधित किए गए मूल टेम्पलेट पाठ सहित उसका पुनः अनुवाद करें।',
  FOLLOW_UP_REMINDER_TEMPLATES: 'अनुवर्ती अनुस्मारक टेम्पलेट्स',
  MANUALLY_MODIFIED: 'मैन्युअल रूप से संशोधित',
  SYNC: 'साथ-साथ करना',
  DIFFERENT: 'अलग',
  DEFAULT_CONFIRMATION_QUESTION: 'यदि आप आगे बढ़ना चाहते हैं तो कृपया पुष्टि करें।',
  SEND_WELCOME_MAIL_: 'पासवर्ड रीसेट के साथ स्वागत ईमेल भेजें',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'मैं पुष्टि करता हूं कि पूछताछकर्ता को नई अनुवर्ती जानकारी प्राप्त हुई है और मैं उत्तर प्रदान करने के लिए बातचीत को पुनः खोलना चाहूंगा।',
  ONLY_LETTERS_SPACE_AND_DASH: 'कृपया केवल अक्षर, स्पेस और - प्रतीक ही दर्ज करें!',
  INFO: 'जानकारी',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'पूछताछकर्ता के देश में परिवर्तन से बातचीत का असाइनमेंट टीम {{ CURRENT_TEAM }} से टीम {{ FUTURE_TEAM }} में बदल जाएगा।',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'पूछताछकर्ता के देश में परिवर्तन से बातचीत का असाइनमेंट टीम {{ CURRENT_TEAM }} से टीम {{ FUTURE_TEAM }} में बदल जाएगा। उपयोगकर्ता {{ ASSIGNED_TO }} के पास {{ FUTURE_TEAM }} टीम के लिए अनुमतियाँ नहीं हैं और उनका व्यक्तिगत असाइनमेंट हटा दिया जाएगा।',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'यह परिवर्तन निम्नलिखित अतिरिक्त इंटरैक्शन में टीम असाइनमेंट को प्रभावित करेगा, जहां व्यक्तिगत असाइनमेंट हटा दिए जाएंगे: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'पूछताछकर्ता के देश में परिवर्तन से निम्नलिखित अतिरिक्त इंटरैक्शन में टीम असाइनमेंट बदल जाएंगे, जहां व्यक्तिगत असाइनमेंट हटा दिए जाएंगे। {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'इंटरेक्शन आईडी: {{ INTERACTION_ID }} {{ CURRENT_TEAM }} से {{ FUTURE_TEAM }} तक, उपयोगकर्ता {{ ASSIGNED_TO }} से व्यक्तिगत असाइनमेंट हटा दिया गया',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'उपयोगकर्ता {{ EDITING_USER }} ने इंटरैक्शन आईडी में पूछताछकर्ता {{ ENQUIRER }} देश को बदल दिया: {{ UPDATED_INTERACTION_ID }}, जिससे वर्तमान इंटरैक्शन का असाइनमेंट टीम {{ OLD_TEAM }} से टीम {{ NEW_TEAM }} में बदल गया।',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'उपयोगकर्ता {{ EDITING_USER }} ने HCP व्यवस्थापन में पूछताछकर्ता {{ ENQUIRER }} देश को बदल दिया, जिससे वर्तमान इंटरैक्शन का असाइनमेंट टीम {{ OLD_TEAM }} से टीम {{ NEW_TEAM }} में बदल गया।',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'उपयोगकर्ता {{ INTERACTION_ASSIGN_TO }} के पास {{ NEW_TEAM }} टीम के लिए अनुमतियाँ नहीं हैं और उनका व्यक्तिगत असाइनमेंट हटा दिया गया है।',
  PERSONAL_ASSIGNMENT: 'व्यक्तिगत असाइनमेंट',
  DUE_TO_FU_REQUEST: 'अनुवर्ती अनुरोध के कारण',
  DUE_TO_FU_RESPONSE: 'अनुवर्ती प्रतिक्रिया के कारण',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'व्यवस्थापक पृष्ठ से अनुवर्ती अनुस्मारक आवृत्ति के अद्यतन के कारण',
  UPDATED_FROM_INBOX: 'इनबॉक्स से अपडेट किया गया',
  ARCHIVED_ROLE: 'संग्रहीत भूमिका',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'उपयोगकर्ता भूमिका को संग्रहीत करने से निम्नलिखित अतिरिक्त इंटरैक्शन में असाइनमेंट बदल जाएंगे, जहां व्यक्तिगत असाइनमेंट हटा दिए जाएंगे: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'उपयोगकर्ता {{ EDITING_USER }} ने {{ INTERACTION_ASSIGN_TO }} उपयोगकर्ता को संपादित किया, तथा उनकी भूमिका को टीम {{ TEAM }} में {{ USER_ROLE }} के रूप में संग्रहित किया। उपयोगकर्ता {{ INTERACTION_ASSIGN_TO }} के पास {{ TEAM }} टीम के लिए अनुमतियाँ नहीं हैं, तथा उनका व्यक्तिगत असाइनमेंट हटा दिया गया है।',
  ASSIGNMENT: 'कार्यभार',
  PERIOD_TO_PERIOD_OVERLAP: 'अवधि और अवधि तक ओवरलैपिंग हैं',
  PERIOD_TO_PERIOD_MISMATCH:
    'अवधि और अवधि तक की अवधि अलग-अलग होती है - {{ PERIOD }} {{ INTERVAL }} और {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'अनुवर्ती प्रतिक्रिया से प्रश्न बनाया गया',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'यह विकल्प अनुवर्ती प्रश्न से नया प्रश्न बनाने की अनुमति देता है।',
  ADD_AS_A_NEW_QUESTION: 'एक नए प्रश्न के रूप में जोड़ें',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'कृपया ध्यान रखें कि आप इस उपयोगकर्ता के लिए अंतिम स्वीकृत भूमिका को संग्रहीत करने का प्रयास कर रहे हैं, जिसके कारण उपयोगकर्ता निष्क्रिय हो जाएगा और EnqMed तक उनकी आगे की पहुंच अवरुद्ध हो जाएगी।',
  ATTEMPTED_FOLLOW_UP: 'अनुवर्ती प्रयास किया',
  FAILED_FU_ATTEMPT: 'अनुवर्ती प्रयास विफल',
  UNABLE_TO_REACH_ENQUIRER: 'पूछताछकर्ता से संपर्क नहीं हो सका।',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'यदि पूछताछकर्ता से संपर्क करने का प्रयास किया गया था, लेकिन सफल नहीं हुआ, उदाहरण के लिए फोन के माध्यम से संपर्क करने में असमर्थ, F2F या अन्य नियुक्ति विफल रही, तो इस विकल्प का चयन करें। प्रयास बातचीत के इतिहास में दर्ज किया जाएगा।',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'आपको उस पृष्ठ पर पुनः निर्देशित किया जाएगा जिसके लिए आपके पास अनुमतियाँ हैं।',
  FU_STOPPED_DUE_TO_ENQ_CHANGE: 'पूछताछकर्ता बदलने के परिणामस्वरूप अनुवर्ती प्रक्रिया रोक दी गई',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'आप पूछताछकर्ता को बदलने वाले हैं, जिससे अनुवर्ती प्रक्रिया रुक जाएगी।',
  INQUIRER_DETAILS: 'पूछताछ विवरण',
  INQUIRER_TYPE: 'पूछताछकर्ता प्रकार',
  DELETE_INQUIRER_PII: 'पूछताछकर्ता की व्यक्तिगत जानकारी मिटाएँ',
  MARKED_INQUIRER_PII_DELETE: 'पूछताछकर्ता की व्यक्तिगत जानकारी को हटाने के लिए चिह्नित किया गया',
  UNMARKED_INQUIRER_PII_DELETE: 'अचिह्नित पूछताछकर्ता की व्यक्तिगत जानकारी हटाने के लिए',
  NEW_ENQUIRER: 'नया पूछताछकर्ता',
  NEW_ENQUIRER_TOOLTIP:
    'इस विकल्प का उपयोग करके एक बिल्कुल नया पूछताछकर्ता बनाएं। यदि इसके बजाय मौजूदा पूछताछकर्ता डेटा को संपादित करना आवश्यक है तो इस विकल्प का उपयोग न करें।',
  CONFIRM_SET_NEW_ENQUIRER:
    'कृपया पुष्टि करें कि आप मौजूदा पूछताछकर्ता को नए से बदलना चाहते हैं।<br>दर्ज की गई सभी जानकारी नष्ट हो जाएगी।<br>यदि आप मौजूदा पूछताछकर्ता के डेटा को संपादित करना चाहते हैं, तो सीधे फॉर्म में डेटा को संशोधित करें और सहेजें दबाएं।',
  INQUIRER: 'इन्क्वायरर',
  KEEP_INQUIRER_DETAILS_OPEN: 'पूछताछ विवरण खुला रखें',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'आपके पास सहेजे नहीं गए परिवर्तन हैं, जो पूछताछ विवरण बंद करने पर नष्ट हो जाएंगे।',
  UPDATED_INQUIRER_DETAILS: 'अद्यतन पूछताछ विवरण',
  CREATED_INQUIRER: 'बनाया गया पूछताछकर्ता',
  SUGGESTED_INQ: 'सुझाए गए पूछताछकर्ता',
  SELECT_INQUIRER: 'पूछताछकर्ता का चयन करें',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'बातचीत प्रस्तुत करने के लिए, एडीआर दायित्व सहमति और पूछताछकर्ता का चयन किया जाना चाहिए',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'गलत ईमेल पता होने के कारण प्रश्नकर्ता को उत्तर नहीं भेजा जा सका।',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'धन्यवाद! पूछताछ प्रस्तुत होने या उत्तर मिलने पर अधिसूचना भेज दी जाएगी।',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'धन्यवाद! पूछताछकर्ता के सुरक्षित हो जाने पर अधिसूचना भेज दी जाएगी।',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'निम्नलिखित GDPR अधिसूचना पूछताछकर्ता को ईमेल के माध्यम से भेजी जाएगी।',
  NOTIFICATION_SENT_TO_THE_INQ:
    'निम्नलिखित GDPR अधिसूचना पूछताछकर्ता को ईमेल के माध्यम से भेज दी गई है।',
  SEND_A_NOTIFICATION_TO_THE_INQ:
    'पूछताछकर्ता को ईमेल के माध्यम से निम्नलिखित GDPR अधिसूचना भेजें।',
  INQ_HAS_NO_EMAIL: 'पूछताछकर्ता के पास कोई ईमेल नहीं है.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'पूछताछकर्ता को यह सूचना नहीं भेजी गई है कि पूछताछ प्राप्त हो गई है।',
  NOTIFICATION_SENT_TO_THE_INQUIRER: 'पूछताछकर्ता को सूचना भेजी गई कि पूछताछ प्राप्त हो गई है।',
  THIS_IS_JUST_A_THANK_YOU: 'यह मौजूदा पूछताछ के पूछताछकर्ता की ओर से एक धन्यवाद संदेश मात्र है।',
  SAVE_INQUIRER: 'सहेजें पूछताछकर्ता',
  INQ_DEFAULT_VAL: 'इन्क्वायरर डिफ़ॉल्ट मान',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'कंपनी प्रतिनिधि (बिक्री प्रतिनिधि, एमएसएल, या अन्य) के माध्यम से प्रस्तुत पूछताछ के लिए, पूछताछकर्ता को उनके व्यक्तिगत डेटा के प्रबंधन के बारे में सूचित करें।',
  SELECT_PREFERRED_CONTACT_ERROR: 'आपको पूछताछकर्ता के लिए पसंदीदा संपर्क चुनना होगा।',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'पूछताछकर्ता द्वारा शुरू की गई अनुवर्ती कार्रवाई',
  NO_INQUIRER: 'कोई पूछताछ नहीं',
  'Changed inquirer': 'पूछताछकर्ता बदला गया',
  QUESTION_REQUIRE_ANSWER_MESSAGE: 'जांच को बंद करने के लिए नए प्रश्न का उत्तर आवश्यक होगा।',
  IMAGE: 'छवि',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'चिकित्सीय क्षेत्र {{ STATUS }} है',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', क्योंकि कोई संबद्ध उत्पाद नहीं हैं। कृपया चिकित्सीय क्षेत्र को सक्रिय करने के लिए कम से कम एक उत्पाद चुनें।',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'सभी चिकित्सीय क्षेत्रों का विस्तार करें',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'सभी उपचारात्मक क्षेत्रों को संकुचित करें',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'किसी उपचारात्मक क्षेत्र (TA) को मुख्य TA के रूप में सेट करने के लिए, मूल TA का चयन रद्द करें और सहेजें।',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'लॉगइन करने के लिए अतिरिक्त चरण की आवश्यकता',
  EXTRA_STEP_DESCRIPTION:
    'आपके ईमेल पर लॉगिन पुष्टिकरण भेज दिया गया है। सिस्टम में आगे बढ़ने के लिए कृपया इसमें दिए गए चरणों का पालन करें।',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'कृपया न्यूनतम 15 अक्षरों का पाठ या अनुलग्नक दर्ज करें।',
  REASON_FOR_REJECTION: 'खारिज करने का कारण',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'नोट को इतिहास में उचित रूप से सम्मिलित कर दिया गया है।',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    '{{ ACTION_TYPE }} द्वारा चयनित {{ METADATA_TYPE }} पर, निम्नलिखित अतिरिक्त संबंधित परिवर्तन लागू होंगे:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'उत्पादों को हटाने से निम्नलिखित अतिरिक्त संबंधित परिवर्तन लागू होंगे:',
  TEAM_NOTIFY_INQUIRER: 'पूछताछकर्ता को सूचित करें',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'इस विकल्प को सक्षम करने से इस टीम में खुली बातचीत में पूछताछ करने वाले उन लोगों को GDPR अधिसूचनाएं स्वचालित रूप से भेज दी जाएंगी, जिन्हें अभी तक सूचित नहीं किया गया है।',
  TEAM_NOTIFY_SALES: 'बिक्री की सूचना दें',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'इस विकल्प को सक्षम करने से इस टीम में बिक्री भूमिका वाले सभी उपयोगकर्ताओं के लिए उत्तर शामिल अधिसूचना सक्रिय हो जाएगी।\n\nइस विकल्प को अक्षम करने से इस टीम में बिक्री भूमिका वाले सभी उपयोगकर्ताओं के लिए उत्तर शामिल अधिसूचना निष्क्रिय हो जाएगी, जब तक कि उनके पास बिक्री को अधिसूचित करने की सेटिंग सक्षम करने वाली किसी अन्य टीम में बिक्री भूमिका न हो।',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'निम्नलिखित अधिसूचना प्राप्त होती है',
  EMAILS: 'ईमेल',
  PHONES: 'फ़ोनों',
  USERS_DEACTIVATED_SUCCESSFULLY: 'उपयोगकर्ता/उपयोगकर्ताओं को सफलतापूर्वक निष्क्रिय कर दिया गया',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'उपयोगकर्ताओं के सामूहिक निष्क्रियण की पुष्टि करें',
  DEACTIVATE_SELECTED_USERS: 'चयनित उपयोगकर्ताओं को निष्क्रिय करें',
  SELECT_USERS_TO_DEACTIVATE: 'निष्क्रिय करने के लिए उपयोगकर्ताओं का चयन करें',
  ARCHIVE_FOLDER: 'संग्रह फ़ोल्डर',
  EXTRA_PARAMS:
    "अतिरिक्त पैरामीटर (k1=v1&k2=v2, 'फ़ोल्डर्स' और 'आर्काइव' आरक्षित हैं और उनका उपयोग नहीं किया जा सकता)",
  POLL_FOLDER: 'पोल फ़ोल्डर',
  POLL_FOLDERS_TEXT:
    'पोल फ़ोल्डर केवल प्रोटोकॉल imap या gmail के लिए सेट किए जा सकते हैं। अन्य सभी प्रोटोकॉल के लिए, डिफ़ॉल्ट इनबॉक्स है।',
  POLL_FOLDERS_TOOLTIP_1:
    'फ़ोल्डर का नाम टाइप करें और एंटर दबाएँ या मेलबॉक्स से पोलिंग के लिए इसे जोड़ने के लिए अल्पविराम का उपयोग करें (यदि कोई फ़ोल्डर नहीं जोड़ा गया है, तो इनबॉक्स डिफ़ॉल्ट रूप से पोल हो जाएगा)।',
  POLL_FOLDERS_TOOLTIP_2: 'यहां सूचीबद्ध फ़ोल्डर नामों को मेलबॉक्स से चुना जाएगा।',
  PERMISSION: 'अनुमति',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे तालिका में मौजूदा भूमिकाओं की जांच करें क्योंकि हमारे पास पहले से ही समान नाम वाली भूमिका मौजूद है।',
  CURR_MAILBOX: 'वर्तमान मेलबॉक्स',
  ENQUIRY_ANSWER_APPROVAL: 'पूछताछ उत्तर अनुमोदन',
  REASON_FOR_INCLUSION: 'शामिल करने का कारण',
  FOLDERS_TO_POLL_FROM_MAILBOX: 'मेलबॉक्स से मतदान करने के लिए फ़ोल्डर',
  VERIFY_SETUP: 'सेटअप सत्यापित करें',
  HTML_PREVIEW: 'HTML पूर्वावलोकन',
  IN_REPLY_TO_ID: 'आईडी के जवाब में',
  INCOMING: 'आने वाली',
  OUTGOING: 'जावक',
  VIEW: 'देखना',
  ROLE_IN_USE_USERS:
    'यह भूमिका वर्तमान में उपयोगकर्ताओं को सौंपी गई है और इसे संग्रहीत करने से उनके पहुँच अधिकार सीमित हो सकते हैं',
  ROLE_IN_USE_USER_TEMPLATES:
    'भूमिका वर्तमान में उपयोगकर्ता टेम्पलेट्स द्वारा उपयोग की जाती है और इसे संग्रहीत करने से यह उनसे हट जाएगी',
  ROLE_IN_USE_LICENSE_COUNTER:
    'यह भूमिका वर्तमान में लाइसेंस काउंटर में उपयोग की जाती है और इसे संग्रहीत करने से यह वहां से हट जाएगी।',
  REASON_FOR_REPLACEMENT: 'प्रतिस्थापन का कारण',
  QUESTION_TEXT_AFTER_SPLIT: 'विभाजन के बाद प्रश्न पाठ',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'टीम के लिए पूछताछ उत्तर अनुमोदन कार्यप्रवाह अक्षम कर दिया जाएगा। "अनुमोदन के लिए सबमिट किया गया" स्थिति में बातचीत प्रभावित होगी और बिना अनुमोदन के सही उत्तर तैयार करने और प्रतिक्रिया देने की अनुमति देने के लिए वापस "प्रगति में" स्थिति में वापस आ जाएगी।',
  REVERT_TO_IN_PROGRESS: 'प्रगति पर वापस लौटें',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'प्रश्न विभाजन तब किया गया, जब बातचीत {{ SUBMITTED_FOR_APPROVAL }} स्थिति में थी। यह क्रिया स्थिति को वापस {{ IN_PROGRESS }} पर वापस लाती है, ताकि नए बनाए गए प्रश्न/प्रश्नों के लिए उत्तर तैयार किया जा सके।',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'क्रिया {{ MERGE_AS_ADDITIONAL_QUESTION }} वर्तमान इंटरैक्शन में निष्पादित की गई, जबकि लक्ष्य इंटरैक्शन {{ SUBMITTED_FOR_APPROVAL }} स्थिति में है। यह क्रिया स्थिति को वापस {{ IN_PROGRESS }} पर वापस लाती है ताकि नए बनाए गए प्रश्न/प्रश्नों के लिए उत्तर तैयार किया जा सके।',
  ENQUIRY_ANSWER_APPROVAL_TITLE: 'चयनित टीमों के लिए पूछताछ उत्तर अनुमोदन सेट करें',
  APPROVAL_ASSIGN_TO: 'अनुमोदन हेतु अनुरोध असाइन करें',
  APPROVAL_ASSIGN_BY: 'द्वारा अनुमोदन असाइन करें',
  APPROVAL_ASSIGNMENTS: 'अनुमोदन असाइनमेंट की जांच करें',
  APPROVAL_ASSIGNMENTS_TO: 'अनुमोदन कार्य हेतु पूछताछ',
  APPROVAL_ASSIGNMENTS_BY: 'द्वारा अनुमोदन कार्य की जांच करें',
  My_pending_approvals: 'मेरी लंबित स्वीकृतियां',
  RULE: 'नियम',
  RULES: 'नियम',
  FIELD: 'मैदान',
  CRITERIA: 'मानदंड',
  NUMBER: 'संख्या',
  MIN: 'मिन',
  MAX: 'अधिकतम',
  ADD_GROUP: 'समूह जोड़ें',
  DELETE_GROUP: 'समूह हटाएं',
  VALID_FROM_TO: 'से/तक वैध',
  NEW_INQ: 'नया प्रश्न',
  NOT_APPROVING_INQUIRIES: 'पूछताछ को मंजूरी नहीं देना',
  DOCUMENT_EXTERNAL_URL: 'दस्तावेज़ बाहरी URL',
  QUESTION_CHARACTERISATION: 'प्रश्न लक्षण वर्णन',
  MAIL_IMPORT_DOMAIN_SETTINGS_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'कृपया संवाद के पीछे की तालिका में मौजूदा डोमेन रेगेक्स की जाँच करें क्योंकि हमने पहले ही समान मानों के साथ डोमेन रेगेक्स को ट्रैक किया है।',
  TEAMS_DOMAINS: 'टीमों के डोमेन',
  MAIL_IMPORT_DOMAIN_SETTINGS: 'मेल आयात डोमेन सेटिंग्स',
  MAIL_IMPORT_DOMAINS_SETTINGS: 'मेल आयात डोमेन सेटिंग्स',
  THE_ACTION_IS_IRREVERSIBLE: 'कार्रवाई अपरिवर्तनीय है!',
  USER_DOMAINS: 'उपयोगकर्ता डोमेन',
  SPAM_DOMAINS: 'स्पैम डोमेन',
  DOMAIN_REGEX: 'डोमेन रेगेक्स',
  REGEX_PYTHON_INFORMATION: 'क्लिक करें, पायथन समर्थित रेगेक्स के बारे में अधिक जानकारी के लिए।',
  USER_DOMAINS_TOOLTIP:
    'परिभाषित करता है कि कौन से डोमेन (विशिष्ट डोमेन या कुंजी शब्द के साथ प्रस्तुत ईमेल) एनक्वायरर फ़ील्ड को खाली छोड़ देंगे।',
  SPAM_DOMAINS_TOOLTIP:
    'उन संदेशों को परिभाषित करता है जिनके साथ डोमेन (विशिष्ट डोमेन या कुंजी शब्द के साथ प्रस्तुत ईमेल) को सिस्टम में प्रवेश करने वाले इंटरैक्शन में परिवर्तित नहीं किया जाएगा, लेकिन स्पैम के रूप में चिह्नित किया जाएगा।',
  EMAIL_SUBJECT_TEMPLATE_HINT_1:
    'ई-मेल विषय टीम {{ TEAM }} को सौंपे गए प्रत्येक इंटरैक्शन में दिखाई देगा, ऐसे मामलों में जहां ई-मेल विषय को मैन्युअल रूप से संपादित नहीं किया गया है और इंटरैक्शन को मैन्युअल रूप से बनाया गया है।',
  EMAIL_SUBJECT_TEMPLATE_HINT_2:
    'ई-मेल के माध्यम से ENQMED को प्रस्तुत किए गए इंटरैक्शन में, ई-मेल विषय को ई-मेल हेडर से लिया जाएगा।',
  CC: 'प्रतिलिपि',
  SET_INTERACTION_AS_BEING_PART_OF_AN_EVENT: 'एक घटना का हिस्सा होने के रूप में बातचीत सेट करें',
  UPDATED_EVENT: 'अद्यतन घटना',
  REMOVED_INTERACTION_AS_BEING_PART_OF_AN_EVENT:
    'एक घटना का हिस्सा होने के रूप में हटाए गए बातचीत को हटा दिया',
  FORWARD: 'आगे',
  VALID_FROM: 'से मान्य होना',
  VALID_TO: 'मान्य के लिए',
  WATCHERS: 'पर नजर रखने वालों',
  WATCH_OPTIONS: 'देखें विकल्प',
  START_WATCHING: 'देखना शुरू करना',
  STOP_WATCHING: 'देखना बंद करें',
  UPDATE_WATCHERS: 'इंटरैक्शन में अपडेट किए गए वॉचर्स',
  ACTIVATE_PERMISSION_EDIT: 'अनुमति संपादित करें',
  DEACTIVATE_PERMISSION_EDIT: 'निष्क्रिय अनुमति संपादित करें',
  SHARE_FROM_HERE_YOU_CAN_:
    'शेयर: यहां से आप आंतरिक/बाहरी उपयोगकर्ताओं या विभागों के लिए इंटरैक्शन डेटा अग्रेषित कर सकते हैं।',
  QUESTIONS_TO_BE_SENT: 'प्रश्न भेजे जाने वाले प्रश्न',
  SHOW_QUESTION: 'प्रश्न दिखाएं',
  HIDE_QUESTION: 'प्रश्नों को छिपाएं',
  SHOW_QUESTION_TEXT: 'प्रश्न पाठ दिखाएं',
  HIDE_QUESTION_TEXT: 'प्रश्न पाठ छिपाएं',
  SEARCH_RECIPIENTS: 'खोज प्राप्तकर्ता',
  SEARCH_PARTICIPANTS: 'प्रतिभागियों को खोजें',
  SEARCH_USER: 'खोज उपयोगकर्ता',
  SEARCH_RECIPIENTS_TO_SHARE_THE_ENQUIRY: 'जांच साझा करने के लिए प्राप्तकर्ताओं को खोजें।',
  SEARCH_USERS_WHICH_WILL_PARTICIPATE_TO_THE_EVENT:
    'उन उपयोगकर्ताओं को खोजें जो घटना में भाग लेंगे।',
  SEARCH_USER_WHICH_WILL_APPROVE_THE_STEP: 'खोज उपयोगकर्ता जो चरण को मंजूरी देगा।',
  RULE_ENQ_ANSWER_APPROVAL_MSG: 'पूछताछ उत्तर अनुमोदन पहले से ही टीम में सक्षम है',
  PERMISSION_EDIT_TOOLTIP: 'अनुमति {{name}} संपादित करने के लिए क्लिक करें',
  ADDITIONAL_REF_ID: '{{ TYPE }} संदर्भ आईडी',
  ADDITIONAL_REF_IDS_TITLE: '{{ TYPE }} संदर्भ आईडी',
  ADD_ADDITIONAL_REF_IDS_TOOLTIP: 'अतिरिक्त {{ TYPE }} संदर्भ आईडी को संपादित / जोड़ें / हटाएं',
  ADD_ADDITIONAL_REF_IDS: '{{ TYPE }} संदर्भ आईडी जोड़ें',
  FWD_TO_DEPT_FAILED: 'विभाग के लिए आगे विफल रहा।',
  RESEND: 'पुन: भेजें',
  GDPR_NOTIF_FAILED: 'रसीद अधिसूचना/GDPR विफल रहा।',
  INTERACTIONS: 'बातचीत',
  NEW_INTERACTION: 'नई बातचीत',
  INTERACTIONS_INBOX: 'बातचीत इनबॉक्स',
  REFERENCE_SOURCE: 'संदर्भ स्रोत',
  REFERENCE_ID: 'संदर्भ आईडी',
  REFERENCE_TYPE: 'संदर्भ प्रकार',
  SAVE_REFERENCE_ID: 'संदर्भ आईडी सहेजें',
  My_open_inquiries: 'मेरी खुली बातचीत',
  My_closed_inquiries: 'मेरी बंद बातचीत',
  Filter_inquiries_by: 'द्वारा इंटरैक्शन को फ़िल्टर करें',
  INBOX_NO_FILTERS_HINT:
    'कोई फिल्टर चयनित नहीं हैं। यदि आप उस इंटरैक्शन को नहीं पा सकते हैं जिसकी आप तलाश कर रहे हैं, तो जांचें कि आप सही इनबॉक्स सेक्शन में हैं।',
  My_teams_open_inq: 'मेरी टीम की खुली बातचीत',
  My_teams_closed_inq: 'मेरी टीम की बंद बातचीत',
  All_teams_open_inq: 'सभी टीमें खुली बातचीत',
  All_teams_closed_inq: 'सभी टीमों ने बातचीत बंद कर दी',
  INTERACTION_REFERENCES: 'सहभागिता संदर्भ',
  INTERACTION_REFERENCE_ID: 'बातचीत संदर्भ आईडी',
  INSERT_NEW_REF_SOURCE: 'नया संदर्भ स्रोत डालें',
  BACK_TO_AVAILABLE_SOURCE: 'उपलब्ध स्रोत पर वापस जाएं',
  CLICK_TO_ENABLE_REF_ID_EDIT: 'संदर्भ आईडी के संपादन को सक्षम करने के लिए क्लिक करें',
  DELETE_REF_ID: 'संदर्भ आईडी हटाएं',
  DELETE_REF_ID_CONFIRMATION_QUESTION:
    'क्या आप सुनिश्चित हैं कि आप इंटरेक्शन संदर्भ आईडी को हटाना चाहते हैं?',
  DUPLICATED_REF_ID_SOURCE_VALUE_WARNING:
    'कृपया मौजूदा संदर्भ स्रोतों को सत्यापित करें, एक ही मूल्य के साथ एक संदर्भ स्रोत के रूप में पहले से मौजूद है।',
};
